<section class="flex flex-col gap-6">
  @for (question of questions; track question.key) {
    <article [id]="question.key" class="scroll-mt-12 [&:target>*]:ring">
      <button
        (click)="
          expand ? expandQuestion(question.key) : selectQuestion(question.key)
        "
        [attr.aria-selected]="
          question.key === selectedQuestionKey ||
          question.key === expandedQuestionKey
        "
        class="ring-dms-green/50 aria-selected:border-dms-green flex w-full max-w-lg flex-col rounded-md border bg-white p-3 text-left text-sm ring-offset-2 enabled:hover:bg-gray-50 disabled:select-text"
      >
        <div
          class="flex w-full flex-wrap-reverse items-start justify-between gap-1.5"
        >
          <h2 [title]="question.label" class="truncate font-semibold">
            {{ question.label }}
          </h2>
          <p
            [title]="question.key"
            class="flex h-6 shrink-0 items-center truncate rounded border border-dashed bg-gray-50 px-2 font-mono text-[11px] tracking-tight text-gray-500"
          >
            {{ question.key }}
          </p>
        </div>
        @if (question.prompt) {
          <dominion-prompt
            [prompt]="question.prompt"
            class="mt-1.5 max-w-prose whitespace-pre-line font-medium text-gray-700"
            [ngClass]="{
              'line-clamp-2': !(
                question.key === expandedQuestionKey ||
                question.key === selectedQuestionKey
              )
            }"
          ></dominion-prompt>
        }
        @if (question.subprompt) {
          <dominion-prompt
            [prompt]="question.subprompt"
            [subprompt]="true"
            class="mt-1.5 max-w-prose whitespace-pre-line text-gray-500"
            [ngClass]="{
              'line-clamp-2': !(
                question.key === expandedQuestionKey ||
                question.key === selectedQuestionKey
              )
            }"
          ></dominion-prompt>
        }

        <div
          class="mt-6 flex w-full flex-wrap items-center justify-between gap-1.5"
        >
          <div class="flex shrink-0 items-center gap-1.5">
            <p
              class="flex h-6 items-center rounded border bg-gray-50 px-2 text-xs capitalize text-gray-500"
            >
              {{ question.componentType }}
            </p>

            @if (question.componentType === 'dropdown') {
              <label
                (click)="$event.stopPropagation()"
                class="border-dms-green/20 bg-dms-green/5 text-dms-green relative flex h-6 max-w-[360px] items-center overflow-x-auto rounded-full border text-xs"
              >
                <select
                  (click)="$event.stopPropagation()"
                  class="h-full w-full appearance-none bg-transparent pl-2 pr-6 focus:outline-none"
                >
                  <option>Options</option>
                  @for (option of question.options; track option.value) {
                    <option disabled>{{ option.label }}</option>
                  }
                </select>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="pointer-events-none absolute right-1 h-4 w-4 bg-transparent"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                  />
                </svg>
              </label>
            }

            @if (
              question.componentType === 'select' && question.options.length > 0
            ) {
              <div
                class="divide-dms-green/20 border-dms-green/20 bg-dms-green/5 flex h-6 max-w-[360px] items-center divide-x overflow-x-auto rounded-full border"
              >
                @for (option of question.options; track option.value) {
                  <div
                    [title]="option.label"
                    class="text-dms-green flex h-full items-center px-2 text-xs"
                  >
                    <span class="max-w-[100px] truncate">{{
                      option.label
                    }}</span>
                    @if (
                      countOptionDependencies(
                        option.value,
                        question.dependencies
                      ) > 0
                    ) {
                      &nbsp;
                      <span class="text-[11px] tabular-nums"
                        >({{
                          countOptionDependencies(
                            option.value,
                            question.dependencies
                          )
                        }})</span
                      >
                    }
                  </div>
                }
              </div>
            }
          </div>

          @if (question.dependencies.length > 0) {
            <div
              class="border-dms-green/20 bg-dms-green/5 text-dms-green flex h-6 shrink-0 items-center gap-1.5 rounded-full border pl-2.5 pr-2 text-xs"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="h-3.5 w-3.5 shrink-0"
              >
                <path
                  d="M20 10a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-2.5a1 1 0 0 1-.8-.4l-.9-1.2A1 1 0 0 0 15 3h-2a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1Z"
                />
                <path
                  d="M20 21a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-2.9a1 1 0 0 1-.88-.55l-.42-.85a1 1 0 0 0-.92-.6H13a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1Z"
                />
                <path d="M3 5a2 2 0 0 0 2 2h3" />
                <path d="M3 3v13a2 2 0 0 0 2 2h3" />
              </svg>
              <span>
                {{ countTotalDependencies(question.dependencies) }}
                {{
                  countTotalDependencies(question.dependencies) > 1
                    ? 'dependencies'
                    : 'dependency'
                }}
              </span>
            </div>
          }
        </div>
      </button>
    </article>

    @if (
      (expand ||
        (selectedQuestion?.key === question.key &&
          question.requirement === 'required')) &&
      question.dependencies.length > 0
    ) {
      <div class="flex flex-col gap-3 pl-12">
        @for (dependency of question.dependencies; track dependency.value) {
          <div>
            <div
              class="text-dms-green/ border-dms-green/20 bg-dms-green/5 text-dms-green inline-flex items-center gap-1.5 rounded-full border p-0.5 pl-3 text-xs"
            >
              If parent {{ dependency.method }}
              <div
                class="bg-dms-green rounded-full px-2 py-0.5 text-xs text-white"
              >
                {{ optionLabelForValue(question, dependency.value) }}
              </div>
            </div>
          </div>

          <div class="border-dms-green/20 border-l pl-6">
            <dominion-question-list
              [expand]="true"
              [groups]="groups"
              [questions]="dependency.questions"
              [selectedModule]="selectedModule"
              [selectedSubmodule]="selectedSubmodule"
              [selectedGroupKey]="selectedGroupKey"
              [selectedQuestionKey]="selectedQuestionKey"
              [expandedQuestionKey]="expandedQuestionKey"
            ></dominion-question-list>
          </div>
        }
      </div>
    }
  }
</section>
