import { IFileUploadReference } from '../../../uploads/upload.interfaces';
import { DataInitializerBasic } from '../../core/core-module.interfaces';
import {
  Structures,
  TDataDefinition,
  TSubmoduleGroupMap,
} from '../../core/core-submodule.interfaces';

export type TAccountingMaintenanceGroupIdentifiers =
  | 'groupARDisclaimer'
  | 'groupAutoEntries'
  | 'groupCustomerReport'
  | 'groupDealerLogo'
  | 'groupFinancialStatement'
  | 'groupJournals'
  | 'groupPartsAccountCodes'
  | 'groupSchedules'
  | 'groupServiceAccountCodes'
  | 'groupTrialBalance'
  | 'groupVehicleSalesPostingTemplates'
  | 'groupDailyReports'
  | 'groupPaymentTypes'
  | 'groupUsedFloorPlan'
  | 'groupVendorReport';

export type TAccountingMaintenanceDataKeys =
  | 'aRDisclaimer'
  | 'useDefaultARDisclaimer'
  | 'autoEntries'
  | 'customerReport'
  | 'dealerLogo'
  | 'dailyReports'
  | 'financialStatement'
  | 'journals'
  | 'partsAccountCodes'
  | 'schedules'
  | 'serviceAccountCodes'
  | 'paymentTypes'
  | 'trialBalance'
  | 'vehicleSalesPostingParams'
  | 'dealPostingManualEntries'
  | 'hasUsedFloorPlan'
  | 'usedFloorPlanAPR'
  | 'hasUsedFreeDays'
  | 'vendorReport';

const accountingMaintenanceGroupMap = {
  moduleType: 'accounting',
  submoduleType: 'maintenance',
  discriminator: 'accounting-maintenance',
  groups: {
    groupUsedFloorPlan: [
      'hasUsedFloorPlan',
      'usedFloorPlanAPR',
      'hasUsedFreeDays',
    ],
    groupPaymentTypes: ['paymentTypes'],
    groupARDisclaimer: ['aRDisclaimer', 'useDefaultARDisclaimer'],
    groupDailyReports: ['dailyReports'],
    groupAutoEntries: ['autoEntries'],
    groupCustomerReport: ['customerReport'],
    groupDealerLogo: ['dealerLogo'],
    groupFinancialStatement: ['financialStatement'],
    groupJournals: ['journals'],
    groupPartsAccountCodes: ['partsAccountCodes'],
    groupSchedules: ['schedules'],
    groupServiceAccountCodes: ['serviceAccountCodes'],
    groupTrialBalance: ['trialBalance'],
    groupVehicleSalesPostingTemplates: [
      'vehicleSalesPostingParams',
      'dealPostingManualEntries',
    ],
    groupVendorReport: ['vendorReport'],
  },
} satisfies TSubmoduleGroupMap<
  TAccountingMaintenanceGroupIdentifiers,
  TAccountingMaintenanceDataKeys
>;

export type TAccountingMaintenanceGroupMap =
  typeof accountingMaintenanceGroupMap;

export class AccountingMaintenanceData
  implements TDataDefinition<TAccountingMaintenanceDataKeys>
{
  hasUsedFloorPlan = new DataInitializerBasic<boolean>();
  usedFloorPlanAPR = new DataInitializerBasic<string>();
  hasUsedFreeDays = new DataInitializerBasic<boolean>();
  aRDisclaimer = new DataInitializerBasic<IFileUploadReference[]>([]);
  autoEntries = new DataInitializerBasic<IFileUploadReference[]>([]);
  dailyReports = new DataInitializerBasic<IFileUploadReference[]>([]);
  customerReport = new DataInitializerBasic<IFileUploadReference[]>([]);
  dealerLogo = new DataInitializerBasic<IFileUploadReference[]>([]);
  financialStatement = new DataInitializerBasic<IFileUploadReference[]>([]);
  journals = new DataInitializerBasic<IFileUploadReference[]>([]);
  partsAccountCodes = new DataInitializerBasic<IFileUploadReference[]>([]);
  schedules = new DataInitializerBasic<IFileUploadReference[]>([]);
  serviceAccountCodes = new DataInitializerBasic<IFileUploadReference[]>([]);
  trialBalance = new DataInitializerBasic<IFileUploadReference[]>([]);
  dealPostingManualEntries = new DataInitializerBasic<IFileUploadReference[]>(
    [],
  );
  vehicleSalesPostingParams = new DataInitializerBasic<IFileUploadReference[]>(
    [],
  );
  vendorReport = new DataInitializerBasic<IFileUploadReference[]>([]);
  paymentTypes = new DataInitializerBasic<IFileUploadReference[]>([]);
  useDefaultARDisclaimer = new DataInitializerBasic<boolean>();
}

export const accountingMaintenanceStructure: Structures['accounting-maintenance'] =
  {
    moduleType: 'accounting',
    submoduleType: 'maintenance',
    discriminator: 'accounting-maintenance',
    groups: {
      groupUsedFloorPlan: {
        groupLabel: 'Used Floor Plan',
        groupUrl: 'groupUsedFloorPlan',
        groupType: 'standard',
        questions: {
          hasUsedFloorPlan: {
            questionKey: 'hasUsedFloorPlan',
            label: 'Used Floor Plan',
            componentType: 'select',
            requirement: 'required',
            allowSkip: false,
            prompt: [
              {
                attributes: null,
                text: 'Do you have a used floor plan?',
              },
            ],
            subprompt: [],
            options: [
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ],
            validation: [],
            dependencies: [
              {
                comparisonMethod: 'equals',
                comparisonValue: true,
                dependentKeys: ['usedFloorPlanAPR', 'hasUsedFreeDays'],
              },
            ],
          },
          usedFloorPlanAPR: {
            questionKey: 'usedFloorPlanAPR',
            label: 'Used Floor Plan APR',
            componentType: 'text',
            requirement: 'dependent',
            allowSkip: false,
            prompt: [
              {
                attributes: null,
                text: 'What is your used floor plan APR?',
              },
            ],
            subprompt: [],
            options: [],
            validation: [],
            dependencies: [],
          },
          hasUsedFreeDays: {
            questionKey: 'hasUsedFreeDays',
            label: 'Used Floor Plan Free Days',
            componentType: 'select',
            requirement: 'dependent',
            allowSkip: false,
            prompt: [
              {
                attributes: null,
                text: 'Do you have used free days?',
              },
            ],
            subprompt: [],
            options: [
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ],
            validation: [],
            dependencies: [],
          },
        },
      },
      groupDailyReports: {
        groupLabel: 'Daily Reports',
        groupType: 'standard',
        groupUrl: 'groupDailyReports',
        questions: {
          dailyReports: {
            questionKey: 'dailyReports',
            label: 'Daily Reports',
            componentType: 'file',
            requirement: 'required',
            allowSkip: true,
            prompt: [
              {
                attributes: null,
                text: 'Please provide a list of your daily reports.',
              },
            ],
            subprompt: [
              {
                attributes: null,
                text: 'For each daily report, please provide:\n    \u2022 the report number\n    \u2022 the report name\n    \u2022 the report parameters (that is, the data needed to\n       properly configure the report)',
              },
            ],
            options: [],
            validation: [],
            dependencies: [],
          },
        },
      },
      groupARDisclaimer: {
        groupLabel: 'Accounts Receivable Disclaimer',
        groupUrl: 'groupARDisclaimer',
        groupType: 'standard',
        questions: {
          useDefaultARDisclaimer: {
            questionKey: 'useDefaultARDisclaimer',
            label: 'Use Default A/R Disclaimer',
            componentType: 'select',
            requirement: 'required',
            allowSkip: false,
            prompt: [
              {
                text: "Do you want to use VUE's default accounts receivable disclaimer?",
                attributes: null,
              },
            ],
            subprompt: [
              {
                attributes: null,
                text: "VUE's default accounts receivable disclaimer:\n\nA FINANCE CHARGE will be applied to all unpaid balances after the due date. Finance charges are computed with a periodic rate of 1.5% per month, which is an ANNUAL PERCENTAGE RATE of 18%. A minimum of $0.50 MIN will be applied to the unpaid balance after deducting current payments and/or credits appearing on the statement.",
              },
            ],
            options: [
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ],
            validation: [],
            dependencies: [
              {
                comparisonMethod: 'equals',
                comparisonValue: false,
                dependentKeys: ['aRDisclaimer'],
              },
            ],
          },
          aRDisclaimer: {
            questionKey: 'aRDisclaimer',
            label: 'Accounts Receivable Disclaimer',
            prompt: [
              {
                text: 'Please upload your A/R disclaimer.',
                attributes: null,
              },
            ],
            subprompt: [],
            componentType: 'file',
            requirement: 'dependent',
            allowSkip: true,
            options: [],
            validation: [],
            dependencies: [],
          },
        },
      },
      groupAutoEntries: {
        groupLabel: 'Automatic Entries',
        groupUrl: 'groupAutoEntries',
        groupType: 'standard',
        questions: {
          autoEntries: {
            questionKey: 'autoEntries',
            label: 'Automatic Entries',
            prompt: [
              {
                text: 'Please provide a list of your automatic entries.',
                attributes: null,
              },
            ],
            subprompt: [
              {
                text: 'For each automatic entry, please provide:\n    \u2022 journal number\n    \u2022 account number\n    \u2022 control\n    \u2022 reference\n    \u2022 description\n    \u2022 amount\n    \u2022 the number of months to repeat the entry',
                attributes: null,
              },
            ],
            componentType: 'file',
            requirement: 'required',
            allowSkip: true,
            options: [],
            validation: [],
            dependencies: [],
          },
        },
      },
      groupCustomerReport: {
        groupLabel: 'Customer Report',
        groupUrl: 'groupCustomerReport',
        groupType: 'standard',
        questions: {
          customerReport: {
            questionKey: 'customerReport',
            label: 'Customer Report',
            prompt: [
              {
                text: 'Please upload a customer report from your retiring DMS with as much customer detail as possible.',
                attributes: null,
              },
            ],
            subprompt: [
              {
                attributes: null,
                text: 'We use this customer report to verify that your customer data is being properly converted from your retiring DMS to VUE.',
              },
            ],
            componentType: 'file',
            requirement: 'required',
            allowSkip: true,
            options: [],
            validation: [],
            dependencies: [],
          },
        },
      },
      groupDealerLogo: {
        groupLabel: 'Dealership Logo',
        groupUrl: 'groupDealerLogo',
        groupType: 'standard',
        questions: {
          dealerLogo: {
            questionKey: 'dealerLogo',
            label: 'Dealership Logo',
            prompt: [
              {
                text: 'Please upload your dealership logos. The logo should be in JPG, TIFF, or PDF file format.',
                attributes: null,
              },
            ],
            subprompt: [
              {
                attributes: { bold: true },
                text: 'Main Logo\n',
              },
              {
                attributes: null,
                text: 'Maximum Height: 1 inch\nMaximum Width: 5 inches\nMinimum Resolution: 300 DPI\n\n',
              },
              {
                attributes: { bold: true },
                text: 'Report Logo\n',
              },
              {
                attributes: null,
                text: 'Maximum Height: 1 inch\nMaximum Width: 2 inches\nMinimum Resolution: 300 DPI',
              },
            ],
            componentType: 'file',
            requirement: 'required',
            allowSkip: true,
            options: [],
            validation: [],
            dependencies: [],
          },
        },
      },
      groupFinancialStatement: {
        groupLabel: 'Financial Statement',
        groupUrl: 'groupFinancialStatement',
        groupType: 'standard',
        questions: {
          financialStatement: {
            questionKey: 'financialStatement',
            label: 'Financial Statement',
            prompt: [
              {
                text: 'Please upload the most recent financial statement reported to your OEM.',
                attributes: null,
              },
            ],
            subprompt: [
              {
                attributes: null,
                text: 'Please ',
              },
              {
                attributes: { bold: true },
                text: 'do not ',
              },
              {
                attributes: null,
                text: 'redact amounts or accounts.',
              },
            ],
            componentType: 'file',
            requirement: 'required',
            allowSkip: true,
            options: [],
            validation: [],
            dependencies: [],
          },
        },
      },
      groupJournals: {
        groupLabel: 'Journals',
        groupUrl: 'groupJournals',
        groupType: 'standard',
        questions: {
          journals: {
            questionKey: 'journals',
            label: 'Journals',
            prompt: [
              {
                text: 'Please provide a list of your journals.',
                attributes: null,
              },
            ],
            subprompt: [
              {
                attributes: null,
                text: 'For each journal, please provide:\n    \u2022 journal number\n    \u2022 journal name\n    \u2022 associated account numbers\n    \u2022 indication of whether the account numbers are\n       posting and/or credit accounts',
              },
            ],
            componentType: 'file',
            requirement: 'required',
            allowSkip: true,
            options: [],
            validation: [],
            dependencies: [],
          },
        },
      },
      groupPartsAccountCodes: {
        groupLabel: 'Parts Account Codes',
        groupUrl: 'groupPartsAccountCodes',
        groupType: 'standard',
        questions: {
          partsAccountCodes: {
            questionKey: 'partsAccountCodes',
            label: 'Parts Account Codes',
            prompt: [
              {
                text: 'Please provide a list of your parts account codes.',
                attributes: null,
              },
            ],
            subprompt: [
              {
                attributes: null,
                text: 'For each account code, please provide:\n    \u2022 the account code\n    \u2022 description\n    \u2022 the pay type (e.g., customer pay, internal pay)',
              },
            ],
            componentType: 'file',
            requirement: 'required',
            allowSkip: true,
            options: [],
            validation: [],
            dependencies: [],
          },
        },
      },
      groupSchedules: {
        groupLabel: 'Schedules',
        groupUrl: 'groupSchedules',
        groupType: 'standard',
        questions: {
          schedules: {
            questionKey: 'schedules',
            label: 'Schedules',
            prompt: [
              {
                text: 'Please provide a list of your schedules.',
                attributes: null,
              },
            ],
            subprompt: [
              {
                attributes: null,
                text: 'For each schedule, please provide:\n    \u2022 the schedule number\n    \u2022 the GL account number\n    \u2022 the control type\n    \u2022 the balance type',
              },
            ],
            componentType: 'file',
            requirement: 'required',
            allowSkip: true,
            options: [],
            validation: [],
            dependencies: [],
          },
        },
      },
      groupServiceAccountCodes: {
        groupLabel: 'Service Account Codes',
        groupUrl: 'groupServiceAccountCodes',
        groupType: 'standard',
        questions: {
          serviceAccountCodes: {
            questionKey: 'serviceAccountCodes',
            label: 'Service Account Codes',
            prompt: [
              {
                text: 'Please provide a list of your service account codes.',
                attributes: null,
              },
            ],
            subprompt: [
              {
                attributes: null,
                text: 'For each account code, please provide:\n    \u2022 the account code\n    \u2022 description\n    \u2022 the pay type (e.g., customer pay, internal pay)',
              },
            ],
            componentType: 'file',
            requirement: 'required',
            allowSkip: true,
            options: [],
            validation: [],
            dependencies: [],
          },
        },
      },
      groupTrialBalance: {
        groupLabel: 'Trial Balance',
        groupUrl: 'groupTrialBalance',
        groupType: 'standard',
        questions: {
          trialBalance: {
            questionKey: 'trialBalance',
            label: 'Trial Balance',
            prompt: [
              {
                text: 'Please provide your trial balance.',
                attributes: null,
              },
            ],
            subprompt: [
              {
                attributes: null,
                text: 'Please ',
              },
              {
                attributes: { bold: true },
                text: 'do not ',
              },
              {
                attributes: null,
                text: 'redact amounts or accounts.',
              },
            ],
            componentType: 'file',
            requirement: 'required',
            allowSkip: true,
            options: [],
            validation: [],
            dependencies: [],
          },
        },
      },
      groupVehicleSalesPostingTemplates: {
        groupLabel: 'Vehicle Sales Posting Templates',
        groupUrl: 'groupVehicleSalesPostingTemplates',
        groupType: 'standard',
        questions: {
          vehicleSalesPostingParams: {
            questionKey: 'vehicleSalesPostingParams',
            label: 'Vehicle Sales Posting Parameters',
            prompt: [
              {
                text: 'Please provide the vehicle sales posting parameters you currently use in your retiring DMS for the following types of sales: cash, retail, lease, wholesale, and house.',
                attributes: null,
              },
            ],
            subprompt: [
              {
                attributes: null,
                text: 'Please provide this information in Excel or XLSX format. Alternatively, provide screenshots of the parameters in your retiring DMS.',
              },
            ],
            componentType: 'file',
            requirement: 'required',
            allowSkip: true,
            options: [],
            validation: [],
            dependencies: [],
          },
          dealPostingManualEntries: {
            questionKey: 'dealPostingManualEntries',
            label: 'Deal Posting Manual Entries',
            prompt: [
              {
                text: 'Please provide any fixed or standard entries that you manually post within the deal posting, such as the $25 contribution to the Holiday Party Fund.',
                attributes: null,
              },
            ],
            subprompt: [
              {
                attributes: null,
                text: 'Please provide this information in Excel or XLSX format. Alternatively, provide screenshots of the fixed and standard entries in your retiring DMS.',
              },
            ],
            componentType: 'file',
            requirement: 'required',
            allowSkip: true,
            options: [],
            validation: [],
            dependencies: [],
          },
        },
      },
      groupVendorReport: {
        groupLabel: 'Vendor Report',
        groupUrl: 'groupVendorReport',
        groupType: 'standard',
        questions: {
          vendorReport: {
            questionKey: 'vendorReport',
            label: 'Vendor Report',
            prompt: [
              {
                text: 'Please upload a vendor report from your retiring DMS with as much vendor detail as possible.',
                attributes: null,
              },
            ],
            subprompt: [],
            componentType: 'file',
            requirement: 'required',
            allowSkip: true,
            options: [],
            validation: [],
            dependencies: [],
          },
        },
      },
      groupPaymentTypes: {
        groupLabel: 'Payment Types',
        groupUrl: 'groupPaymentTypes',
        groupType: 'standard',
        questions: {
          paymentTypes: {
            questionKey: 'paymentTypes',
            label: 'Payment Types',
            componentType: 'file',
            requirement: 'required',
            allowSkip: true,
            prompt: [
              {
                attributes: null,
                text: 'Please provide a list of your accepted payment types.',
              },
            ],
            subprompt: [
              {
                attributes: null,
                text: 'For each payment type, please provide:\n    \u2022 the description/name\n    \u2022 the associated GL account number',
              },
            ],
            options: [],
            validation: [],
            dependencies: [],
          },
        },
      },
    },
  } satisfies Structures['accounting-maintenance'];

export type AccountingMaintenanceGroupRoutes =
  (typeof accountingMaintenanceStructure)['groups'][keyof (typeof accountingMaintenanceStructure)['groups']]['groupUrl'];
