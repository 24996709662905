<dominion-core-card title="Companies" [count]="matched.length">
  <dominion-table
    [hideFrame]="true"
    [isLoading]="isLoadingExternalCompanies || !user"
    [isEmpty]="companies.length === 0"
    class="mt-4"
  >
    <thead>
      <tr>
        <th>Company Name</th>
        <th>Auth Role</th>
        <th>Assigned Modules</th>
        <th><span class="sr-only">View</span></th>
      </tr>
    </thead>
    <tbody>
      @for (match of matched; track match.company._id) {
        <tr>
          <td>
            @if (match.company.name) {
              <span class="font-semibold" [title]="match.company.name">{{
                match.company.name
              }}</span>
            } @else {
              <span class="text-gray-400">&mdash;</span>
            }
          </td>
          <td>
            @if (
              user!.authorizations['company:' + match.company!._id].roles[0]
            ) {
              <div class="capitalize">
                {{
                  user!.authorizations['company:' + match.company!._id].roles[0]
                }}
              </div>
            } @else {
              <span class="text-gray-400">&mdash;</span>
            }
          </td>
          <td>
            @if (match.assignment && match.assignment.modules) {
              @for (module of match.assignment.modules; track module._id) {
                {{
                  $index + 1 !== match.assignment.modules.length
                    ? (module.moduleType | moduleType) + ','
                    : (module.moduleType | moduleType)
                }}
              } @empty {
                <span class="text-gray-400">&mdash;</span>
              }
            } @else {
              <span class="text-gray-400">&mdash;</span>
            }
          </td>
          <td>
            <button
              class="text-dms-green hover:bg-dms-light-green/20 group flex h-7 w-7 cursor-pointer items-center truncate rounded-sm p-[6px] text-sm font-medium"
              (click)="goToCompany(match.company._id)"
              title="Go to company"
            >
              <dominion-icon-go-to
                strokeColor="stroke-dms-green group-hover:stroke-dms-dark-green"
              ></dominion-icon-go-to>
            </button>
          </td>
        </tr>
      }
    </tbody>
  </dominion-table>
</dominion-core-card>
