<!-- Static Element -->
<div
  *ngIf="!isEditing"
  (click)="activateEditing()"
  class="inline-block border border-transparent border-solid hover:border hover:border-dashed hover:border-gray-400 cursor-pointer {{
    padding
  }}"
  [ngClass]="{ 'hover:cursor-not-allowed hover:border-transparent': disabled }"
  data-testid="editable-text-content"
>
  {{ currentValue ? currentValue.label : '' }}
</div>
<!-- Editable Element -->
<div
  dominionPopoverHost
  *ngIf="isEditing"
  [formGroup]="form"
  class="inline-block border border-dms-mid-green border-solid {{
    padding
  }} cursor-pointer"
  data-testid="editable-text-active"
>
  <!-- Select -->
  <select
    dominionPopoverTarget
    #selectEl
    formControlName="selectInput"
    class="flex py-[2px] px-0 outline-none cursor-pointer {{ bgColor }}"
    (blur)="deactivateEditing()"
    (change)="save()"
    data-testid="editable-select-input"
    (keyup)="keyUp($event)"
  >
    <!-- Options -->
    <option value="" [disabled]="required" selected>Select...</option>
    <option *ngFor="let option of options" [value]="option.value">
      {{ option.label }}
    </option>
  </select>
  <!-- Error Popover -->
  <div
    dominionPopover
    class="text-sm font-normal text-red-600"
    data-testid="editable-text-popover"
  >
    <ng-container *ngIf="form.invalid">
      {{ errorMessage }}
    </ng-container>
    <ng-container *ngIf="form.invalid && serverErrMsg">
      <br />
    </ng-container>
    <ng-container *ngIf="serverErrMsg">
      {{ serverErrMsg }}
    </ng-container>
  </div>
</div>
