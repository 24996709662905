@if (!isParentLoading && user) {
  @if (user.status === 'active') {
    <div class="flex w-full max-w-full flex-row space-x-4">
      <!-- EMAIL -->
      <div
        class="[&.external]:bg-dms-light-green/10 group flex min-w-0 flex-initial basis-2/5 flex-col space-y-1 rounded px-5 py-3 [&.deactivated]:bg-red-100 [&.external]:basis-1/2 [&.internal]:bg-gray-100/75"
        [ngClass]="{
          external: !user.isDeactivated && user.userType === 'external',
          deactivated: user.isDeactivated,
          internal: user.userType === 'internal' && !user.isDeactivated
        }"
      >
        <div class="flex flex-row text-xs text-gray-500">
          <div
            class="text-dms-green ml-[2px] flex flex-row px-1 uppercase group-[.deactivated]:text-red-700 group-[.internal]:text-gray-500"
          >
            email
          </div>
        </div>
        <div
          class="text-dms-dark-green flex flex-row font-bold group-[.deactivated]:text-red-900"
        >
          <dominion-in-situ-text
            #email
            [allowNull]="false"
            [isDisabled]="true"
            [initialValue]="user.email"
            [defaultValue]="'User Email'"
            [formErrMsg]="'Must provide a valid email address'"
            (saved)="onUserUpdate({ field: 'email', value: $event }, email)"
          ></dominion-in-situ-text>
        </div>
      </div>

      <!-- JOB TITLE -->
      <div
        class="[&.external]:bg-dms-light-green/10 group flex min-w-0 flex-initial basis-1/5 flex-col space-y-1 rounded px-5 py-3 [&.deactivated]:bg-red-100 [&.external]:basis-1/4 [&.internal]:bg-gray-100/75"
        [ngClass]="{
          external: !user.isDeactivated && user.userType === 'external',
          deactivated: user.isDeactivated,
          internal: user.userType === 'internal' && !user.isDeactivated
        }"
      >
        <div class="flex flex-row text-xs text-gray-500">
          <div
            class="text-dms-green flex flex-row px-1 uppercase group-[.deactivated]:text-red-700 group-[.internal]:text-gray-500"
          >
            title
          </div>
        </div>
        <div
          class="text-dms-dark-green flex flex-none flex-row font-bold group-[.deactivated]:text-red-900"
        >
          <dominion-in-situ-text
            #jobTitle
            [allowNull]="false"
            [isDisabled]="!canEditUserDetails"
            [initialValue]="user.jobTitle ? user.jobTitle : null"
            [defaultValue]="'Job Title'"
            [formErrMsg]="'Must provide a job title'"
            (saved)="
              onUserUpdate({ field: 'jobTitle', value: $event }, jobTitle)
            "
          ></dominion-in-situ-text>
        </div>
      </div>

      <!-- DEPARTMENT -->
      <div
        class="[&.external]:bg-dms-light-green/10 group flex min-w-0 flex-initial basis-1/5 flex-col space-y-1 rounded px-5 py-3 [&.deactivated]:bg-red-100 [&.external]:basis-1/4 [&.internal]:bg-gray-100/75"
        [ngClass]="{
          external: !user.isDeactivated && user.userType === 'external',
          deactivated: user.isDeactivated,
          internal: user.userType === 'internal' && !user.isDeactivated
        }"
      >
        <div class="flex flex-row text-xs text-gray-500">
          <div
            class="text-dms-green group-[.internal]:text-gray-500s flex flex-row px-1 uppercase group-[.deactivated]:text-red-700 group-[.internal]:text-gray-500"
          >
            department
          </div>
        </div>
        <div
          class="text-dms-dark-green flex flex-none flex-row font-bold group-[.deactivated]:text-red-900"
        >
          <dominion-in-situ-select
            #department
            [options]="
              user.userType === 'internal'
                ? dominionDeptOptions
                : companyDeptOptions
            "
            [initialValue]="user.department"
            [defaultValue]="'Department'"
            [allowNull]="false"
            (saved)="
              onUserUpdate({ field: 'department', value: $event }, department)
            "
            [isDisabled]="!canEditUserDetails"
            [config]="{
              viewType: 'editable',
              togglePaddingX: 'px-1',
              togglePaddingY: 'py-1',
              searchable: false,
              toggleTextSize: 'text-base'
            }"
          ></dominion-in-situ-select>
        </div>
      </div>

      <!-- DOMINION AUTH -->
      @if (user.userType === 'internal') {
        <div
          class="[&.external]:bg-dms-light-green/10 group flex min-w-0 flex-initial basis-1/5 flex-col space-y-1 rounded px-5 py-3 [&.deactivated]:bg-red-100 [&.external]:basis-1/4 [&.internal]:bg-gray-100/75"
          [ngClass]="{
            external: !user.isDeactivated,
            deactivated: user.isDeactivated,
            internal: user.userType === 'internal' && !user.isDeactivated
          }"
        >
          <div class="flex flex-row text-xs text-gray-500">
            <div
              class="text-dms-green group-[.internal]:text-gray-500s flex flex-row px-1 uppercase group-[.deactivated]:text-red-700 group-[.internal]:text-gray-500"
            >
              Auth Role
            </div>
          </div>
          <div
            class="text-dms-dark-green flex flex-none flex-row font-bold group-[.deactivated]:text-red-900"
          >
            <dominion-in-situ-select
              #dominionAuth
              [options]="dominionAuthOption"
              [initialValue]="getUserAuth()"
              [defaultValue]="'Role'"
              [allowNull]="false"
              (saved)="setDominionAuth($event)"
              [isDisabled]="!canEditUserDetails"
              [config]="{
                viewType: 'editable',
                togglePaddingX: 'px-1',
                togglePaddingY: 'py-1',
                searchable: false,
                toggleTextSize: 'text-base'
              }"
            ></dominion-in-situ-select>
          </div>
        </div>
      }
    </div>
  }
} @else {
  <div class="flex h-12 flex-row space-x-4">
    <dominion-loading-placeholder
      class="basis-1/2"
    ></dominion-loading-placeholder>
    <dominion-loading-placeholder
      class="basis-1/4"
    ></dominion-loading-placeholder>
    <dominion-loading-placeholder
      class="basis-1/4"
    ></dominion-loading-placeholder>
  </div>
}
