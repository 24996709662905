import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import {
  ICreateVendorDto,
  IVendor,
  IVendorQuestion,
  IVendorUpdateDto,
} from '@dominion/interfaces';
import { VendorService } from './vendor.service';
import { ModalComponent } from '../shared/modal/modal.component';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DropdownOption } from '../shared/dropdown-search/dropdown-search.component';
import { OptionToggleComponent } from '../shared/option-toggle/option-toggle.component';
import { VendorQuestionsComponent } from '../vendor-questions/vendor-questions.component';
import { SearchBarComponent } from '../shared/search-bar/search-bar.component';
import { FormInputComponent } from '../form-input/form-input.component';
import { IconGoToComponent } from '../icons/icon-go-to.component';
import { TableComponent } from '../shared/table/table.component';

@Component({
  selector: 'dominion-vendors',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    OptionToggleComponent,
    VendorQuestionsComponent,
    SearchBarComponent,
    OptionToggleComponent,
    FormInputComponent,
    IconGoToComponent,
    TableComponent,
  ],
  providers: [VendorService],
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.css'],
})
export class VendorsComponent implements OnInit {
  activeSearch: boolean = false;
  searchResults: IVendor[] = [];
  selectedView: DropdownOption = {
    label: 'Vendor View',
    value: 'vendor',
  };
  page: number;
  pages: number;
  vendorCount: number;
  vendors: IVendor[] = [];
  vendorQuestions: IVendorQuestion[] = [];
  isLoadingVendors = false;
  addVendorForm: FormGroup;
  manageVendorForm: FormGroup;
  isAddingVendor = false;

  modalVendor: IVendor | undefined;

  vendorQuestionToggleOptions: DropdownOption[] = [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Parts',
      value: 'parts',
    },
    {
      label: 'Service',
      value: 'service',
    },
    {
      label: 'Accounting',
      value: 'accounting',
    },
    {
      label: 'F&I',
      value: 'fi',
    },
    {
      label: 'Sales',
      value: 'sales',
    },
    {
      label: 'Payroll',
      value: 'payroll',
    },
  ];
  selectedModuleGroup: DropdownOption = {
    label: 'All',
    value: 'all',
  };

  @ViewChild('addVendorModal') addVendorModal: ModalComponent;
  @ViewChild('manageVendorModal') manageVendorModal: ModalComponent;
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;

  constructor(
    private vendorService: VendorService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.addVendorForm = this.fb.group({
      providerName: ['', Validators.required],
      appName: ['', Validators.required],
    });
    this.manageVendorForm = this.fb.group({
      providerName: ['', Validators.required],
      appName: ['', Validators.required],
    });
    this.getVendors(1);
    this.getVendorQuestions();
  }

  getVendorQuestions() {
    this.vendorService.getVendorQuestions().subscribe({
      next: (questions) => {
        this.vendorQuestions = questions;
      },
    });
  }

  selectVendorQuestionGroup(option: DropdownOption) {
    this.selectedModuleGroup = option;
  }

  getVendors(page: number) {
    if (!page) {
      page = 1;
    }
    this.isLoadingVendors = true;
    this.vendorService.getVendors(page).subscribe({
      next: (ref) => {
        this.page = page;
        this.pages = Math.ceil(ref.count / 50);
        this.vendorCount = ref.count;
        this.vendors = ref.vendors;
        this.searchResults = ref.vendors;
        //this.sortVendors();

        this.isLoadingVendors = false;
      },
    });
  }

  setSelectedView(view: DropdownOption) {
    this.activeSearch = false;
    this.selectedView = view;
  }

  sortVendors() {
    this.vendors.sort((a, b) => {
      const providerA = a.providerName.toLowerCase();
      const providerB = b.providerName.toLowerCase();
      if (providerA < providerB) {
        return -1;
      } else if (providerA > providerB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  createVendor() {
    if (this.addVendorForm.valid) {
      this.isAddingVendor = true;
      const dto: ICreateVendorDto = this.addVendorForm.value;
      this.vendorService.createVendor(dto).subscribe({
        next: (vendor) => {
          this.isAddingVendor = false;
          this.vendors.push(vendor);
          this.vendors = [...this.vendors];
          this.sortVendors();
          this.cancelCreateVendor();
        },
      });
    }
  }

  updateVendor() {
    if (this.manageVendorForm.valid && this.modalVendor) {
      const dto: IVendorUpdateDto = this.manageVendorForm.value;
      dto.vendorId = this.modalVendor._id;
      this.vendorService.updateVendor(dto).subscribe({
        next: () => {
          const index = this.vendors.findIndex(
            (vendor) => vendor._id === dto.vendorId,
          );
          this.vendors[index].providerName = dto.providerName ?? '';
          this.vendors[index].appName = dto.appName ?? '';
          this.vendors = [...this.vendors];
          this.sortVendors();
          this.manageVendorModal.close();
        },
      });
    }
  }

  cancelUpdateVendor() {
    this.manageVendorForm.reset();
    this.manageVendorModal.close();
  }

  cancelCreateVendor() {
    this.addVendorForm.reset();
    this.addVendorModal.close();
  }

  nextPage() {
    if (this.page < this.pages) {
      this.getVendors(this.page + 1);
    }
  }

  previousPage() {
    if (this.page > 1) {
      this.getVendors(this.page - 1);
    }
  }

  firstPage() {
    this.getVendors(1);
  }

  lastPage() {
    this.getVendors(this.pages);
  }

  openCreateVendorModal() {
    this.addVendorModal.open();
  }

  openManageVendorModal(vendor: IVendor) {
    this.modalVendor = vendor;
    this.manageVendorForm.setValue({
      providerName: vendor.providerName,
      appName: vendor.appName,
    });
    this.manageVendorModal.open();
  }

  searchVendors(value: string) {
    if (value === '') {
      this.activeSearch = false;
      this.searchResults = this.vendors;
    }
    this.isLoadingVendors = true;
    this.activeSearch = true;
    this.searchResults = this.vendors.filter((vendor) => {
      const vendorString = `${vendor.providerName.toLowerCase()} ${vendor.appName.toLowerCase()}`;
      return vendorString.includes(value.toLowerCase());
    });

    this.isLoadingVendors = false;
    this.searchBar.done();
  }
}
