<!-- Static Version -->
<!-- This is the non-editing version of the component -->
@if (!isEditing) {
  <div
    #static
    id="static"
    [title]="initialValue ? initialValue : defaultValue"
    (click)="activateEditing()"
    [class]="
      'flex max-w-full cursor-pointer flex-row items-center [&.disabled]:cursor-not-allowed [&.disabled]:border-transparent [&.disabled]:hover:border-transparent [&.placeholder]:italic [&.placeholder]:text-gray-500' +
      staticClasses
    "
    [ngClass]="{
      disabled: isDisabled,
      placeholder: !initialValue
    }"
    data-testid="static"
  >
    <span class="inline-block max-w-full truncate">
      {{ initialValue ? initialValue : defaultValue }}
    </span>
  </div>
}

<!-- Dynamic Version -->
<!-- This is the editing version of the component -->
@if (isEditing) {
  <div
    #dynamic
    dominionPopoverHost
    [class]="'flex max-w-full flex-grow flex-row items-center' + dynamicClasses"
    [formGroup]="form"
  >
    <input
      #inputEl
      dominionPopoverTarget
      type="text"
      formControlName="text"
      [placeholder]="initialValue ? initialValue : defaultValue"
      class="flex max-w-full flex-grow bg-transparent outline-none"
      (blur)="save()"
      (keyup)="keyup($event)"
    />
    <div dominionPopover popoverTextColor="text-red-700">
      @if (isFormErr) {
        {{ formErrMsg }}
      } @else if (isServerErr) {
        {{ serverErrMsg }}
      }
    </div>
  </div>
}
