import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Editable } from '../../../interfaces/editable.interfaces';
import { PopoverDirective } from '../../directives/popover.directive';
import { PopoverHostDirective } from '../../directives/popover-host.directive';
import { PopoverTargetDirective } from '../../directives/popover-target.directive';
import { CustomStyleOptional } from '../../../interfaces/custom-style.interface';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'dominion-in-situ-text',
  standalone: true,
  imports: [
    CommonModule,
    PopoverDirective,
    PopoverHostDirective,
    PopoverTargetDirective,
    ReactiveFormsModule,
  ],
  templateUrl: './in-situ-text.component.html',
  styleUrls: ['./in-situ-text.component.css'],
})
export class InSituTextComponent
  implements Editable<unknown>, OnChanges, OnInit
{
  @HostBinding('class') classes = 'flex grow flex-row max-w-full';
  // INPUTS
  @Input({ required: true }) initialValue: string | null;
  @Input({ required: true }) isDisabled: boolean;
  @Input({ required: true }) defaultValue: string | null;
  @Input({ required: true }) allowNull: boolean;
  @Input() minLength: number | null = null;
  @Input() maxLength: number | null = null;
  @Input() pattern: RegExp | null = null;
  /**
   * The error message to be displayed on invalid input value
   *
   * @example 'Must be a valid email address'
   */
  @Input({ required: true }) formErrMsg: string;
  /**
   * Configuration for the static state of the component
   *
   * @remarks
   * These styles are for the component in its non-editing state
   */
  @Input() staticClassConfig: CustomStyleOptional = {};
  staticClassDefaults: CustomStyleOptional = {
    bgColor: 'bg-transparent',
    paddingX: 'px-1',
    paddingY: 'py-1',
    marginX: '',
    marginY: '',
    borderRadius: 'rounded-sm',
    borderWidth: 'border',
    borderColor: 'border-transparent',
    borderStyle: 'border-solid',
    hoverBorderWidth: 'hover:border',
    hoverBorderColor: 'hover:border-dms-green',
    hoverBorderStyle: 'hover:border-dashed',
    hoverBgColor: 'hover:bg-transparent',
  };

  /**
   * Configuration for the editing state of the component
   *
   * @remarks
   * These styles are for the component in its editing state
   */
  @Input() dynamicClassConfig: CustomStyleOptional = {};
  dynamicClassDefaults: CustomStyleOptional = {
    bgColor: 'bg-white',
    paddingX: 'px-1',
    paddingY: 'py-1',
    marginX: '',
    marginY: '',
    borderRadius: 'rounded-sm',
    borderWidth: 'border',
    borderColor: 'border-dms-green',
    borderStyle: 'border-solid',
    hoverBorderWidth: '',
    hoverBorderColor: '',
    hoverBorderStyle: '',
    hoverBgColor: '',
  };

  // OUTPUTS
  @Output() saved: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() cancelled: EventEmitter<void> = new EventEmitter<void>();
  @Output() startedEditing: EventEmitter<void> = new EventEmitter<void>();

  // STATE
  /**
   * Whether the component is in the editing state
   */
  public isEditing: boolean = false;
  /**
   * Whether the provided text input is invalid
   */
  public isFormErr: boolean = false;
  /**
   * Whether the server returned an error
   */
  public isServerErr: boolean = false;
  /**
   * The error message from the server
   */
  public serverErrMsg: string | undefined;

  staticClasses: string;
  dynamicClasses: string;

  // FORM
  public form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      text: [''],
    });
  }

  @ViewChild('inputEl')
  inputEl: ElementRef<HTMLInputElement>;
  @ViewChild(PopoverHostDirective)
  popoverHost: PopoverHostDirective;

  ngOnInit(): void {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['initialValue']) {
      this.setInitialValue();
    }
  }

  /**
   * Performs initialization tasks
   *
   * @remarks
   * This component needs to initialize its validators and form values.
   */
  initialize(): void {
    this.setValidators();
    this.setInitialValue();
    this.buildClasses();
  }

  // CUSTOM METHODS
  /**
   * Activate the editing state
   */
  activateEditing(): void {
    if (this.isDisabled) {
      return;
    }
    this.isEditing = true;
    this.startedEditing.emit();
    setTimeout(() => {
      this.inputEl.nativeElement.focus();
    }, 0);
  }

  /**
   * Set the validators for the form
   */
  private setValidators() {
    if (!this.allowNull) {
      this.form.get('text')!.addValidators(Validators.required);
    }
    if (this.minLength) {
      this.form
        .get('text')!
        .addValidators(Validators.minLength(this.minLength));
    }
    if (this.maxLength) {
      this.form
        .get('text')!
        .addValidators(Validators.maxLength(this.maxLength));
    }
    if (this.pattern) {
      this.form.get('text')!.addValidators(Validators.pattern(this.pattern));
    }
  }

  /**
   * Set the initial value for the form
   */
  private setInitialValue() {
    if (this.initialValue) {
      this.form.get('text')!.setValue(this.initialValue);
    } else {
      this.form.get('text')!.setValue(this.defaultValue);
    }
  }

  /**
   * Build the classes for the component
   *
   * @remarks
   * Composes the class defaults with the custom class configuration provided by the client.
   */
  private buildClasses() {
    const staticConfig = Object.assign(
      this.staticClassDefaults,
      this.staticClassConfig,
    );
    const dynamicConfig = Object.assign(
      this.dynamicClassDefaults,
      this.dynamicClassConfig,
    );
    const statics = Object.values<string>(staticConfig);
    const dynamics = Object.values<string>(dynamicConfig);
    this.staticClasses = statics.join(' ');
    this.dynamicClasses = dynamics.join(' ');
  }

  /**
   * Event handler for keyup events from the input element
   *
   * @param event - The keyboard event
   *
   * @remarks
   * Save on Enter, cancel on Escape
   */
  keyup(event: KeyboardEvent) {
    event.stopPropagation();
    if (event.key === 'Enter') {
      this.save();
    }
    if (event.key === 'Escape') {
      this.cancel();
    }
  }

  /**
   * Reset the form and server errors
   */
  resetErrors() {
    this.isFormErr = false;
    this.isServerErr = false;
    this.serverErrMsg = undefined;
    this.popoverHost.hide();
  }

  // EDITABLE INTERFACE METHODS

  save(): void {
    this.resetErrors();
    if (!this.form.valid) {
      this.isFormErr = true;
      this.popoverHost.show();
      return;
    }
    if (this.initialValue !== this.form.get('text')!.value) {
      this.saved.emit(this.form.get('text')!.value);
      return;
    }
    this.isEditing = false;
  }

  cancel(): void {
    this.isEditing = false;
    this.resetErrors();
    this.form.get('text')!.setValue(this.initialValue);
  }

  handleError(err: string): void {
    this.serverErrMsg = err;
    this.isServerErr = true;
    this.popoverHost.show();
  }

  handleSuccess(): void {
    this.resetErrors();
    this.isEditing = false;
  }
}
