<div
  #static
  *ngIf="!isEditing"
  (click)="activateEditing()"
  class="inline-block border border-transparent hover:border hover:border-dashed hover:border-gray-400 cursor-pointer {{
    padding
  }} "
  [ngClass]="{
    'cursor-not-allowed border-none hover:cursor-not-allowed hover:border-none':
      disabled
  }"
  data-testid="editable-text-content"
>
  {{ text ? text : placeholder }}
</div>

<div
  #dynamic
  dominionPopoverHost
  *ngIf="isEditing"
  [formGroup]="form"
  class="inline-block border border-dms-mid-green border-solid {{ padding }}"
  data-testid="editable-text-active"
>
  <input
    dominionPopoverTarget
    #inputEl
    [type]="type"
    formControlName="text"
    [placeholder]="text ? text : placeholder"
    class="flex outline-none {{ bgColor }}"
    [size]="getInputSize()"
    (keyup)="keyUp($event)"
    (blur)="deactivateEditing(); maybeSaveOnBlur()"
    (input)="resetErrors()"
    data-testid="editable-text-input"
  />
  <div
    dominionPopover
    class="text-sm font-normal text-red-600"
    data-testid="editable-text-popover"
  >
    <ng-container *ngIf="form.invalid">
      {{ errorMessage }}
    </ng-container>
    <ng-container *ngIf="form.invalid && serverErrMsg">
      <br />
    </ng-container>
    <ng-container *ngIf="serverErrMsg">
      {{ serverErrMsg }}
    </ng-container>
  </div>
</div>
