<div class="ml-4 mt-4 flex flex-row items-center text-sm text-gray-600">
  <span [routerLink]="['../']" class="cursor-pointer hover:underline">
    Dashboard
  </span>
  <div class="mx-2 flex w-3 flex-row items-center justify-center">
    <dominion-icon-right-caret
      [strokeColor]="'stroke-gray-800'"
    ></dominion-icon-right-caret>
  </div>
  <span>Manage Account</span>
</div>

<div class="flex justify-center">
  <div
    class="bg-dms-mid-green flex h-16 w-16 items-center justify-center rounded"
  >
    <span class="text-5xl uppercase text-white">
      {{ tokenUser?.firstName?.charAt(0) }}
    </span>
  </div>
</div>
<div class="mt-5 flex justify-center">
  <div *ngIf="!isComponentLoading" class="text-3xl font-bold">
    <dominion-editable-text
      #firstName
      [text]="user?.firstName"
      [errorMessage]="'Please enter a first name'"
      placeholder="First Name"
      (saved)="onUserUpdate({ field: 'firstName', value: $event }, firstName)"
      data-testid="firstName"
    ></dominion-editable-text>
    <dominion-editable-text
      #lastName
      [text]="user?.lastName"
      [errorMessage]="'Please enter a last name'"
      (saved)="onUserUpdate({ field: 'lastName', value: $event }, lastName)"
      data-testid="lastName"
    ></dominion-editable-text>
  </div>
  <div *ngIf="isComponentLoading" class="h-12 w-64">
    <dominion-loading-placeholder
      data-testid="name-loading-placeholder"
    ></dominion-loading-placeholder>
  </div>
</div>
<div *ngIf="user?.userType === 'internal'" class="flex justify-center">
  <div
    *ngIf="user?.userType === 'internal'"
    class="text-dms-mid-green text-sm font-light uppercase"
  >
    DominionDMS
  </div>
</div>

<!-- Container -->
<div
  *ngIf="!isComponentLoading"
  class="mx-auto mt-8 max-w-md flex-col space-y-2 text-lg"
>
  <!-- Row -->
  <div class="flex gap-3">
    <div class="flex-0 flex items-center">
      <div class="text-dms-mid-green relative h-6 w-6">
        <svg
          id="Regular"
          xmlns="http://www.w3.org/2000/svg"
          class="absolute"
          viewBox="0 0 24 24"
        >
          <defs>
            <style>
              .i-title {
                fill: none;
                stroke: currentColor;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 1.5px;
              }
            </style>
          </defs>
          <title>Title</title>
          <path
            class="i-title"
            d="M23.25,15a3,3,0,0,1-3,3H3.75a3,3,0,0,1-3-3"
          />
          <path
            class="i-title"
            d="M17.271,6.414a3,3,0,0,0-2.978-.036l-.836.464a3,3,0,0,1-2.914,0l-.836-.464a3,3,0,0,0-2.978.036C4.262,7.866,7.211,8.772,3.75,18h16.5C16.785,8.759,19.742,7.868,17.271,6.414Z"
          />
        </svg>
      </div>
    </div>
    <div class="flex flex-auto items-center">
      <dominion-editable-text
        #jobTitle
        [text]="user?.jobTitle"
        [errorMessage]="'Please enter your job title'"
        (saved)="onUserUpdate({ field: 'jobTitle', value: $event }, jobTitle)"
        data-testid="jobTitle"
      ></dominion-editable-text>
    </div>
  </div>
  <!-- Row -->
  <div class="flex gap-3">
    <div class="flex-0 flex items-center">
      <div class="text-dms-mid-green relative h-6 w-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="absolute"
          viewBox="0 0 24 24"
        >
          <defs>
            <style>
              .i-dept {
                fill: none;
                stroke: currentColor;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 1.5px;
              }
            </style>
          </defs>
          <title>Department</title>
          <rect
            class="i-dept"
            x="0.75"
            y="15.748"
            width="7.5"
            height="7.5"
            rx="1.5"
            ry="1.5"
          />
          <rect
            class="i-dept"
            x="8.25"
            y="0.748"
            width="7.5"
            height="7.5"
            rx="1.5"
            ry="1.5"
          />
          <rect
            class="i-dept"
            x="15.75"
            y="15.748"
            width="7.5"
            height="7.5"
            rx="1.5"
            ry="1.5"
          />
          <path
            class="i-dept"
            d="M4.5,15.748v-1.5a1.5,1.5,0,0,1,1.5-1.5H18a1.5,1.5,0,0,1,1.5,1.5v1.5"
          />
          <line class="i-dept" x1="12" y1="8.248" x2="12" y2="12.748" />
        </svg>
      </div>
    </div>
    <div
      class="flex flex-auto cursor-not-allowed items-center p-1 capitalize"
      data-testid="department"
    >
      {{ user?.department }}
    </div>
  </div>
  <!-- Row -->
  <div class="flex gap-3">
    <div class="flex-0 flex items-center">
      <div class="text-dms-mid-green relative h-6 w-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="absolute"
          viewBox="0 0 24 24"
        >
          <defs>
            <style>
              .i-email {
                fill: none;
                stroke: currentColor;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 1.5px;
              }
            </style>
          </defs>
          <title>E-Mail</title>
          <rect
            class="i-email"
            x="0.75"
            y="4.5"
            width="22.5"
            height="15"
            rx="1.5"
            ry="1.5"
          />
          <line class="i-email" x1="15.687" y1="9.975" x2="19.5" y2="13.5" />
          <line class="i-email" x1="8.313" y1="9.975" x2="4.5" y2="13.5" />
          <path
            class="i-email"
            d="M22.88,5.014l-9.513,6.56a2.406,2.406,0,0,1-2.734,0L1.12,5.014"
          />
        </svg>
      </div>
    </div>
    <div
      class="flex flex-auto cursor-not-allowed items-center p-1 lowercase"
      data-testid="email"
    >
      {{ user?.email }}
    </div>
  </div>
</div>

<div
  *ngIf="isComponentLoading"
  class="mx-auto mt-8 w-full max-w-lg flex-col space-y-2"
  data-testid="details-loading-placeholder"
>
  <div class="flex h-8 flex-auto gap-x-1">
    <div class="flex-0 w-12">
      <dominion-loading-placeholder></dominion-loading-placeholder>
    </div>
    <div class="flex-auto">
      <dominion-loading-placeholder></dominion-loading-placeholder>
    </div>
  </div>
  <div class="flex h-8 flex-auto gap-x-1">
    <div class="flex-0 w-12">
      <dominion-loading-placeholder></dominion-loading-placeholder>
    </div>
    <div class="flex-auto">
      <dominion-loading-placeholder></dominion-loading-placeholder>
    </div>
  </div>
  <div class="flex h-8 flex-auto gap-x-1">
    <div class="flex-0 w-12">
      <dominion-loading-placeholder></dominion-loading-placeholder>
    </div>
    <div class="flex-auto">
      <dominion-loading-placeholder></dominion-loading-placeholder>
    </div>
  </div>
</div>

<div class="flex justify-center pt-12">
  <button
    class="flex-0 border-dms-light-grey hover:border-dms-light-green cursor-pointer rounded-sm border p-1 px-4 text-sm hover:shadow"
    (click)="sendForgotPasswordRequest()"
  >
    Send Password Reset Email
  </button>
</div>
