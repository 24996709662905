import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { PopoverHostDirective } from '../directives/popover-host.directive';
import { format } from 'date-fns';

@Component({
  selector: 'dominion-editable-text',
  templateUrl: './editable-text.component.html',
  styleUrls: ['./editable-text.component.css'],
})
export class EditableTextComponent implements OnInit {
  @Input() text: string | undefined | null;
  @Input() type: 'text' | 'date' | 'password' = 'text';
  @Input() minLength: number | undefined;
  @Input() maxLength: number | undefined;
  @Input() placeholder: string | undefined;
  @Input() pattern: RegExp | undefined;
  @Input() errorMessage: string | undefined;
  @Input() maxInputSize: number = 20;
  @Input() minInputSize: number = 4;
  @Input() padding: string = 'p-1';
  @Input() disabled: boolean = false;
  @Input() bgColor: string = 'bg-white';

  @ViewChild(PopoverHostDirective, {
    static: false,
  })
  popoverHost: PopoverHostDirective | undefined;

  @ViewChild('inputEl') input: ElementRef<HTMLInputElement>;
  @ViewChild('staticEl') static: ElementRef<HTMLDivElement>;
  @ViewChild('dynamic') dynamic: ElementRef<HTMLDivElement>;

  @Output() saved: EventEmitter<string> = new EventEmitter<string>();

  public form: FormGroup;
  public isEditing: boolean = false;
  public serverErrMsg: string | undefined;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      text: ['', Validators.required],
    });
  }

  /*
    INITIALIZATION
  */

  ngOnInit(): void {
    this.initialize();
  }

  private initialize() {
    this.setValidators();
    this.setInitialValue();
  }

  private setValidators() {
    const validators: ValidatorFn[] = [];
    if (this.minLength) {
      validators.push(Validators.minLength(this.minLength));
    }
    if (this.maxLength) {
      validators.push(Validators.maxLength(this.maxLength));
    }
    if (this.type === 'date') {
      validators.push(Validators.pattern(/^\d{4}-\d{2}-\d{2}$/));
    }
    if (this.pattern) {
      validators.push(Validators.pattern(this.pattern));
    }
    if (validators.length > 0) {
      this.form.get('text')!.addValidators(validators);
    }
  }

  private setInitialValue() {
    if (this.text) {
      this.form.get('text')!.setValue(this.text);
    } else if (this.type === 'date') {
      this.form.get('text')!.setValue(format(new Date(), 'yyyy-MM-dd'));
    }
  }

  getInputSize() {
    const contentLength = this.form.get('text')!.value
      ? this.form.get('text')!.value.length
      : 0;
    if (contentLength === 0) {
      if (this.text && this.text.length < this.maxInputSize) {
        return this.text.length;
      }
      return this.minInputSize;
    } else if (contentLength > this.maxInputSize) {
      return this.maxInputSize;
    } else {
      return contentLength;
    }
  }

  /*
    EDITING STATE
  */

  activateEditing() {
    if (this.disabled) {
      return;
    }
    this.setInitialValue();
    this.isEditing = true;
    setTimeout(() => {
      this.input.nativeElement.focus();
      if (this.maxLength) {
        this.input.nativeElement.setAttribute(
          'maxlength',
          this.maxLength.toString(),
        );
      }
      if (this.minLength) {
        this.input.nativeElement.setAttribute(
          'minlength',
          this.minLength.toString(),
        );
      }
    }, 0);
  }

  deactivateEditing() {
    this.isEditing = false;
    this.resetErrors();
  }

  maybeSaveOnBlur() {
    if (this.type === 'date' && this.form.get('text')!.value) {
      this.save();
    }
  }

  keyUp(event: KeyboardEvent) {
    event.stopPropagation();
    if (event.key === 'Enter') {
      this.save();
    }
    if (event.key === 'Escape') {
      this.deactivateEditing();
    }
  }

  /*
    SAVING
  */

  private save() {
    this.resetErrors();
    if (this.form.get('text')!.valid) {
      this.saved.emit(this.form.get('text')!.value);
      return;
    }
    this.showError();
  }

  saveSucceeded() {
    this.isEditing = false;
  }

  saveFailed(errMsg: string) {
    this.serverErrMsg = errMsg;
    this.showError();
  }

  /*
    ERRORS
  */

  private showError() {
    this.popoverHost?.show();
  }

  private hideError() {
    this.popoverHost?.hide();
  }

  resetErrors() {
    this.serverErrMsg = undefined;
    this.hideError();
  }
}

/*

Initialization:

 - should set validators on the form
 - should set initial value on the form
 - should set the placeholder

 Inactive State:

 - should display the text value or placeholder
  - should not display the input
  - should not display the error message
  - should display border on hover
  - should have pointer cursor
  - should convert to active editing state when clicked

  Active Editing State:

  - should display the input
  - should display the error message if there is one
  - should display the current input value
  - escape button should revert to inactive state
  - enter button should save the value



*/
