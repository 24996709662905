import {
  Component,
  HostBinding,
  OnInit,
  Pipe,
  PipeTransform,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import {
  DataInitializerBasic,
  ICoreModule,
  ICoreSubmodule,
  IRequestUserPayload,
  IUserReadPrivateBrief,
  ResponseOptions,
  TDiscoveryDataPartial,
  TGenericGroup,
  TGenericQStruct,
  TSubmoduleDiscriminators,
  accountingPreliminaryStructure,
  infraPreliminaryStructure,
  isKeyOf,
  partsPreliminaryStructure,
  servicePreliminaryStructure,
} from '@dominion/interfaces';
import { CompanyService } from '../company/company.service';
import { ModalComponent } from '../shared/modal/modal.component';
import { DiscoveryGroupTitleComponent } from '../shared/discovery-group-title/discovery-group-title.component';
import { CommonModule, DatePipe, NgFor } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { CompletionStatusBarComponent } from '../shared/completion-status-bar/completion-status-bar.component';
import { HubspotTrackingService } from '../services/hubspot-tracking.service';
import { IconClipboardComponent } from '../icons/icon-clipboard.component';
import { TableComponent } from '../shared/table/table.component';
import { CoreCardComponent } from '../shared/core-card/core-card.component';
import { partsMaintenanceStructure } from 'libs/interfaces/src/lib/modules/parts/maintenance/parts-maintenance-data.interfaces';
import { salesPreliminaryStructure } from 'libs/interfaces/src/lib/modules/sales/prelim/sales-prelim-data.interfaces';
import { salesMaintenanceStructure } from 'libs/interfaces/src/lib/modules/sales/maintenance/sales-maintenance-data.interfaces';
import { accountingMaintenanceStructure } from 'libs/interfaces/src/lib/modules/accounting/maintenance/accounting-maintenance-data.interfaces';
import { fiPreliminaryStructure } from 'libs/interfaces/src/lib/modules/fi/prelim/fi-prelim-data.interfaces';
import { serviceMaintenanceStructure } from 'libs/interfaces/src/lib/modules/service/maintenance/service-maintenance-data.interfaces';
import { payrollPreliminaryStructure } from 'libs/interfaces/src/lib/modules/payroll/prelim/payroll-prelim-data.interfaces';
import { payrollMaintenanceStructure } from 'libs/interfaces/src/lib/modules/payroll/maintenance/payroll-maintenance-data.interfaces';
import { UserService } from '../user/user.service';
import { ModuleTypePipe } from '../shared/pipes/module-type.pipe';
import { ModuleIdService } from '../services/module-id.service';
import { AuthenticationService } from '../auth/authentication.service';

const structurePlaceholder = {
  groups: {
    group1: {
      groupLabel: '',
      groupType: 'standard' as const,
      groupUrl: '',
      questions: {
        q1: {
          label: 'q1',
          questionKey: '',
          prompt: [],
          subprompt: [],
          allowSkip: false,
          componentType: 'text' as const,
          requirement: 'required' as const,
          options: [],
          validation: [],
          dependencies: [],
        },
      },
    },
  },
};

@Pipe({
  name: 'questionDataPipe',
  standalone: true,
})
export class QuestionDataPipe implements PipeTransform {
  transform(
    value: TGenericQStruct<string>,
    data: TDiscoveryDataPartial,
  ): string {
    if (!data) {
      throw new Error('No data definition');
    }
    const questionData = (data as any)[value.questionKey];
    // this is a temporary fix for undefined errors in the summary
    if (questionData === undefined) {
      return 'Unknown';
    }

    if ('value' in questionData && questionData.value === null) {
      return '';
    }

    switch (value.componentType) {
      case 'text':
      case 'textarea':
        return questionData.value;
      case 'dropdown':
      case 'select':
        return getMatchingOptionLabel(
          questionData.value,
          value.options,
        ).toString();
      case 'address':
        return `${questionData.value.street} ${questionData.value.city}, ${questionData.value.state} ${questionData.value.zip}`;
      case 'date':
        return new DatePipe('short').transform(questionData.value) || '';
      case 'month-day':
        return `${questionData.value.month} ${questionData.value.day}`;
      case 'file':
        return questionData.value?.[0]?.name || 'No File Uploaded';
      case 'vendor':
        return questionData.value ? 'Vendor Selected' : 'No Vendor Selected';
      case 'contact':
        return questionData.value ? 'Contact provided' : 'No Contact Provided';
      default:
        return '[Unknown Response]';
    }
  }
}

function getMatchingOptionLabel(
  value: string | number,
  options: ResponseOptions[],
) {
  return options.find((option) => option.value === value)?.label || value;
}

@Pipe({
  name: 'promptPipe',
  standalone: true,
})
export class PromptPipe implements PipeTransform {
  transform(value: TGenericQStruct<string>['prompt']): string {
    return value.map((prompt) => prompt.text).join('');
  }
}

@Pipe({
  name: 'groupPipe',
  standalone: true,
})
export class GroupPipe implements PipeTransform {
  transform(value: {
    [key: string]: TGenericGroup<string>;
  }): TGenericGroup<string>[] {
    return Object.values(value);
  }
}

@Pipe({
  name: 'questionPipe',
  standalone: true,
})
export class QuestionPipe implements PipeTransform {
  transform(value: {
    [key: string]: TGenericQStruct<string>;
  }): TGenericQStruct<string>[] {
    return Object.values(value);
  }
}

@Component({
  selector: 'dominion-discovery-module-dashboard',
  templateUrl: './discovery-module-dashboard.component.html',
  styleUrls: ['./discovery-module-dashboard.component.css'],
  imports: [
    DiscoveryGroupTitleComponent,
    CommonModule,
    NgFor,
    QuestionPipe,
    PromptPipe,
    RouterLink,
    QuestionDataPipe,
    GroupPipe,
    IconClipboardComponent,
    SharedModule,
    CompletionStatusBarComponent,
    TableComponent,
    CoreCardComponent,
    ModuleTypePipe,
  ],
  standalone: true,
})
export class DiscoveryModuleDashboardComponent implements OnInit {
  user: IRequestUserPayload | undefined;
  companyId?: string;
  moduleId?: string;
  submodules: Array<ICoreSubmodule<TSubmoduleDiscriminators>> = [];
  module?: ICoreModule;
  modalGroup?: TGenericGroup<string>;
  assignedUser?: IUserReadPrivateBrief;
  isLoadingAssignedUser: boolean = true;

  @HostBinding('class') class = 'w-full overflow-auto';
  @ViewChildren('responseModal') responseModals: QueryList<ModalComponent>;

  // submodule ui maps
  maps = {
    parts: {
      preliminary: partsPreliminaryStructure,
      maintenance: partsMaintenanceStructure,
      default: structurePlaceholder,
    },
    sales: {
      preliminary: salesPreliminaryStructure,
      maintenance: salesMaintenanceStructure,
      default: structurePlaceholder,
    },
    accounting: {
      preliminary: accountingPreliminaryStructure,
      maintenance: accountingMaintenanceStructure,
      default: structurePlaceholder,
    },
    service: {
      preliminary: servicePreliminaryStructure,
      maintenance: serviceMaintenanceStructure,
      default: structurePlaceholder,
    },
    fi: {
      preliminary: fiPreliminaryStructure,
      maintenance: fiPreliminaryStructure,
      default: structurePlaceholder,
    },
    payroll: {
      preliminary: payrollPreliminaryStructure,
      maintenance: payrollMaintenanceStructure,
      default: structurePlaceholder,
    },
    infrastructure: {
      preliminary: infraPreliminaryStructure,
      maintenance: structurePlaceholder,
      default: structurePlaceholder,
    },
  };

  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private companyService: CompanyService,
    private userService: UserService,
    private hubspot: HubspotTrackingService,
    private moduleIdService: ModuleIdService,
  ) {
    this.hubspot.trackPageView('/discovery-module-dashboard');
  }

  getGroupCompletionStatus(
    submodule: ICoreSubmodule<TSubmoduleDiscriminators>,
    groupKey: string,
  ): number {
    if (submodule && isKeyOf(submodule.meta.completion.groups, groupKey)) {
      const { total, complete } = submodule.meta.completion.groups[groupKey];
      return Math.ceil((complete / total) * 100);
    }
    return 0;
  }

  getGroupActionLabel(
    submodule: ICoreSubmodule<TSubmoduleDiscriminators>,
    groupKey: string,
  ): string {
    if (submodule && isKeyOf(submodule.meta.completion.groups, groupKey)) {
      const { total, complete } = submodule.meta.completion.groups[groupKey];
      if (complete >= total) {
        return 'Revisit';
      } else if (complete === 0) {
        return 'Start';
      } else {
        return 'Continue';
      }
    }
    return 'Unknown';
  }

  getGroupLink(
    submodule: ICoreSubmodule<TSubmoduleDiscriminators>,
    groupUrl: string,
  ) {
    return [
      '/core',
      this.companyId,
      'module',
      this.moduleId,
      submodule.submoduleType,
      submodule._id,
      groupUrl,
    ];
  }

  isDataInitializerMap(
    data: any,
  ): data is Record<string, DataInitializerBasic<any>> {
    return data && typeof data === 'object' && !Array.isArray(data);
  }

  getSubmoduleData() {
    const { companyId, module, moduleId } = this;
    if (!companyId || !module || !moduleId) {
      return;
    }

    const submoduleKeys = Object.keys(module.submodules) as Array<
      keyof typeof module.submodules
    >;

    submoduleKeys.map((submoduleKey) => {
      const { submoduleId } = module.submodules[submoduleKey];
      if (typeof submoduleId === 'undefined') {
        return;
      }
      this.companyService
        .getSubmoduleData({
          submoduleId,
        })
        .subscribe((response) => {
          if (response) {
            const { moduleType } = response;
            if (moduleType === 'fi' || moduleType === 'infrastructure') {
              if (submoduleKey === 'preliminary') {
                this.submodules[0] = response;
              }
              return;
            }
            if (submoduleKey === 'preliminary') {
              this.submodules[0] = response;
            }
            if (submoduleKey === 'maintenance') {
              this.submodules[1] = response;
            }
            // TODO(phase3): enable once phase 3 is ready
            // if (submoduleKey === 'default') {
            //   this.submodules[2] = response;
            // }
          }
        });
    });
  }

  getModuleData() {
    if (this.companyId && this.moduleId) {
      this.companyService
        .getModule({ companyId: this.companyId, moduleId: this.moduleId })
        .subscribe((module) => {
          this.module = module;
          this.getSubmoduleData();
          this.getAssignedUser();
        });
    }
  }

  openResponseModal(group: TGenericGroup<string>, index: number) {
    this.modalGroup = group;
    this.responseModals.toArray()[index].open();
  }

  getSubmoduleResponse(questionKey: string): string {
    const submoduleData = this.submodules[0]?.data;

    if (!isKeyOf(submoduleData, questionKey)) {
      return 'Unknown Response';
    }

    // @ts-expect-error - todo
    const value = submoduleData[questionKey].value;
    if (value === null) {
      return '[No Response]';
    }

    if (typeof value === 'string' || typeof value === 'number') {
      const valueMap: Record<string, string> = {
        annually: 'Annually',
        monthly: 'Monthly',
        weekly: 'Weekly',
        biweekly: 'Biweekly',
        'semi-monthly': 'Semi-Monthly',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        centralized: 'Centralized',
        dispersed: 'Dispersed',
        manual: 'Manual',
        electronic: 'Electronic',
        'keep-open': 'Keep Open',
        'close-before': 'Close Before',
        crm: 'CRM',
        dms: 'DMS',
      };
      return valueMap[value] || value.toString();
    } else if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    } else if (typeof value === 'object') {
      if (value instanceof Date) {
        return value.toDateString();
      }
      if ('vendorType' in value) {
        return 'Vendor Selected (Open Group)';
      }
      if ('dateUploaded' in value) {
        return 'File Uploaded';
      }
    }
    return 'Unknown Response';
  }

  getAssignedUser() {
    if (this.module?.externalAssigned[0])
      this.userService
        .getUserDetail(this.module.externalAssigned[0])
        .subscribe({
          next: (user) => {
            this.assignedUser = user;
            this.isLoadingAssignedUser = false;
          },
        });
  }

  getAssignedUserPath() {
    if (!this.assignedUser || !this.user) {
      return '#!';
    }
    if (this.user.userType === 'internal') {
      return ['/core/admin/users', this.assignedUser._id];
    }
    return ['/core', this.companyId, 'users', this.assignedUser._id];
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.companyId = params['companyid'];
      this.moduleId = params['moduleid'];
      this.moduleIdService.setModuleId(params['moduleid']);
      this.getModuleData();
    });
    this.authService.user.subscribe({
      next: (user) => {
        this.user = user;
      },
    });
  }
}
