<div class="relative">
  <label
    for="question-search"
    class="focus-within:border-dms-green flex w-full items-center rounded-sm border pl-2 text-sm transition hover:shadow"
  >
    <div class="h-4 w-4 shrink-0">
      <dominion-icon-search></dominion-icon-search>
    </div>
    <span class="sr-only">Search all questions</span>
    <input
      id="question-search"
      type="text"
      [(ngModel)]="search"
      placeholder="Search all questions..."
      class="h-7 w-full bg-transparent px-2 placeholder:text-sm focus:outline-none"
    />

    @if (search.length > 0) {
      <div
        (click)="search = ''"
        class="flex flex-none shrink-0 cursor-pointer flex-row px-2"
      >
        <div class="h-3 w-3 fill-none stroke-gray-800">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <defs>
              <style>
                .i-close-search {
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-width: 1.5px;
                }
              </style>
            </defs>
            <title>close</title>
            <line
              class="i-close-search"
              x1="0.75"
              y1="23.249"
              x2="23.25"
              y2="0.749"
            />
            <line
              class="i-close-search"
              x1="23.25"
              y1="23.249"
              x2="0.75"
              y2="0.749"
            />
          </svg>
        </div>
      </div>
    }
  </label>

  @if (filteredQuestions.length > 0 && search.length > 0) {
    <ul
      class="absolute right-0 top-10 max-h-[420px] w-full overflow-y-auto rounded border bg-white px-1.5 shadow-xl"
    >
      @for (question of filteredQuestions; track question.groupKey) {
        <li class="py-1.5">
          <button
            (click)="selectQuestion(question)"
            class="w-full p-1.5 text-left hover:bg-gray-100"
          >
            <p class="truncate text-sm font-medium">
              {{ question.questionLabel }}
            </p>
            <p class="truncate text-xs text-gray-500">
              {{ question.questionPrompt }}
            </p>
          </button>
        </li>
        <hr class="mx-1.5 last:hidden" />
      }
    </ul>
  }
</div>
