import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PopoverHostDirective } from '../directives/popover-host.directive';

export interface EditableSelectOption {
  value: string | boolean;
  label: string;
}

@Component({
  selector: 'dominion-editable-select',
  templateUrl: './editable-select.component.html',
  styleUrls: ['./editable-select.component.css'],
})
export class EditableSelectComponent implements OnInit {
  @Input('currentValue') currentValue: EditableSelectOption | undefined;
  @Input('options') options: EditableSelectOption[] | undefined;
  @Input('errorMessage') errorMessage: string | undefined;
  @Input('required') required: boolean = false;
  @Input('padding') padding: string = 'p-1';
  @Input('disabled') disabled: boolean = false;
  @Input('bgColor') bgColor: string = 'bg-white';

  @ViewChild(PopoverHostDirective, {
    static: false,
  })
  popoverHost: PopoverHostDirective | undefined;
  @ViewChild('selectEl') selectEl: ElementRef<HTMLSelectElement>;

  @Output() selected: EventEmitter<any> = new EventEmitter<any>();

  public form: FormGroup;
  public isEditing: boolean = false;
  public serverErrMsg: string | undefined;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      selectInput: [''],
    });
  }

  /*
    INITIALIZATION
  */

  initialize() {
    this.setInitialValue();
  }

  setInitialValue() {
    this.form
      .get('selectInput')!
      .setValue(this.currentValue ? this.currentValue.value : '');
  }

  setValidators() {
    if (this.required) {
      this.form.get('selectInput')!.setValidators(Validators.required);
    }
  }

  /*
    EDITING STATE
  */

  activateEditing() {
    if (this.disabled) {
      return;
    }
    this.setInitialValue();
    this.isEditing = true;
    setTimeout(() => {
      this.selectEl.nativeElement.focus();
    });
  }

  deactivateEditing() {
    this.isEditing = false;
    this.resetErrors();
  }

  keyUp(event: KeyboardEvent) {
    event.stopPropagation();
    if (event.key === 'Escape') {
      this.deactivateEditing();
    }
  }

  /*
    SAVING
  */

  public save() {
    this.resetErrors();
    if (this.form.valid) {
      this.selected.emit(this.form.get('selectInput')!.value);
      return;
    }
  }

  public saveSucceeded() {
    this.deactivateEditing();
  }

  public saveFailed(errMsg: string) {
    this.serverErrMsg = errMsg;
    this.showErr();
  }

  /*
    ERRORS
  */

  private showErr() {
    this.popoverHost!.show();
  }

  private hideErr() {
    this.popoverHost!.hide();
  }

  resetErrors() {
    this.serverErrMsg = undefined;
    this.hideErr();
  }

  ngOnInit(): void {
    this.initialize();
  }
}
