import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { UserService } from '../user.service';
import {
  AuthorizationStrategy,
  ICompany,
  IInvitedUserRead,
  IRequestUserPayload,
  IUserReadPrivate,
  IUserUpdateDto,
  isActiveUserRead,
} from '@dominion/interfaces';
import { AuthorizationService } from '../../auth/authorization.service';
import {
  CompanyAdminStrategy,
  DominionAdminStrategy,
  DominionProjectManagerStrategy,
  DominionSuperStrategy,
} from '@dominion/authorization';
import { IUserUpdateEvent } from '../../manage-account/manage-account.component';
import { HttpErrorResponse } from '@angular/common/http';
import { DropdownOption } from '../../shared/dropdown-search/dropdown-search.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { UserInfoComponent } from '../user-info/user-info.component';
import { UserCompaniesComponent } from '../user-companies/user-companies.component';
import { CoreCardComponent } from '../../shared/core-card/core-card.component';
import { Editable } from '../../interfaces/editable.interfaces';
import { IconShieldComponent } from '../../icons/icon-shield.component';
import { PopoverDirective } from '../../shared/directives/popover.directive';
import { PopoverHostDirective } from '../../shared/directives/popover-host.directive';
import { PopoverTargetDirective } from '../../shared/directives/popover-target.directive';
import { UserAssignmentsComponent } from '../user-assignments/user-assignments.component';
import { IconMenuComponent } from '../../icons/icon-menu.component';
import { InSituSelectComponent } from '../../shared/inputs/in-situ-select/in-situ-select.component';

@Component({
  selector: 'dominion-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    UserInfoComponent,
    UserCompaniesComponent,
    CoreCardComponent,
    IconShieldComponent,
    PopoverDirective,
    PopoverHostDirective,
    PopoverTargetDirective,
    SharedModule,
    UserAssignmentsComponent,
    IconMenuComponent,
    InSituSelectComponent,
    RouterLink,
  ],
})
export class UserDetailComponent implements OnInit {
  public internalView: boolean = false;
  @HostBinding() class = 'basis-5/6 overflow-y-scroll grow p-4';
  user: IUserReadPrivate | IInvitedUserRead | undefined;
  activeUser: IUserReadPrivate;
  invitedUser: IInvitedUserRead;
  actingUser: IRequestUserPayload | undefined;
  isLoading: boolean = true;
  canEditUserDetails: boolean = false;
  canSetDominionSuper: boolean = false;
  canSetDominionAdmin: boolean = false;
  canSetDominionProjectManager: boolean = false;
  dominionAuthOptions: DropdownOption[] = [
    {
      label: 'Super',
      value: 'super',
      disabled: !this.canSetDominionSuper,
    },
    {
      label: 'Admin',
      value: 'admin',
      disabled: !this.canSetDominionAdmin,
    },
    {
      label: 'Project Manager',
      value: 'projectmanager',
      disabled: !this.canSetDominionProjectManager,
    },
    {
      label: 'Deployment Manager',
      value: 'deploymentmanager',
      disabled: !this.canSetDominionProjectManager,
    },
    {
      label: 'Deployment Specialist',
      value: 'deploymenttechnician',
      disabled: !this.canSetDominionProjectManager,
    },
    {
      label: 'Viewer',
      value: 'viewer',
      disabled: !this.canSetDominionProjectManager,
    },
  ];
  selectedDominionAuth: DropdownOption | undefined;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    public authorization: AuthorizationService,
  ) {}

  onUserUpdate(
    event: IUserUpdateEvent,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    editableComp: Editable<any>,
  ) {
    const dto: IUserUpdateDto = {
      [event.field]: event.value,
    };
    this.updateUser(dto, editableComp);
  }

  private updateUser(
    dto: IUserUpdateDto,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    editableComp: Editable<any>,
  ) {
    // call service
    this.userService
      .updateUser(this.user!._id as unknown as string, dto)
      .subscribe({
        next: () => {
          this.user = Object.assign(this.user!, dto);
          editableComp.handleSuccess();
        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          editableComp.handleError(err.error.message);
        },
      });
  }

  initializePermissions() {
    if (!this.user || !this.authorization) {
      return;
    }
    const strategies: AuthorizationStrategy = [
      new DominionProjectManagerStrategy(),
    ];
    if (this.user) {
      for (const company of this.user.companies as Pick<ICompany, '_id'>[]) {
        strategies.push(new CompanyAdminStrategy(company._id));
      }
    }
    let dominionUserRole = '';
    if (this.user && this.user.authorizations['dominion:']) {
      dominionUserRole = this.user.authorizations['dominion:'].roles[0];
    }
    this.canEditUserDetails = this.authorization.isAuthorized(strategies);
    if (this.user?._id === this.actingUser?._id) {
      this.canEditUserDetails = true;
    }
    this.canSetDominionSuper = this.authorization.isAuthorized([
      new DominionSuperStrategy(),
    ]);
    this.canSetDominionAdmin = this.authorization.isAuthorized([
      new DominionSuperStrategy(),
    ]);
    this.canSetDominionProjectManager = this.authorization.isAuthorized([
      dominionUserRole === 'admin'
        ? new DominionSuperStrategy()
        : new DominionAdminStrategy(),
    ]);
    this.setDominionAuthOptions();
  }

  getUser(id: string) {
    // get the user
    this.isLoading = true;
    this.userService.getUserDetail(id).subscribe({
      next: (user) => {
        this.user = user;
        this.isLoading = false;
        if (isActiveUserRead(user)) {
          this.activeUser = user;
          this.setSelectedDominionAuthOption();
        } else {
          this.invitedUser = user;
        }
        this.initializePermissions();
      },
    });
  }

  setSelectedDominionAuthOption() {
    if (!this.user || this.user.userType === 'external') return;
    switch (this.user?.authorizations['dominion:'].roles[0]) {
      case 'super':
        this.selectedDominionAuth = this.dominionAuthOptions[0];
        break;
      case 'admin':
        this.selectedDominionAuth = this.dominionAuthOptions[1];
        break;
      case 'projectmanager':
        this.selectedDominionAuth = this.dominionAuthOptions[2];
        break;
      case 'deploymentmanager':
        this.selectedDominionAuth = this.dominionAuthOptions[3];
        break;
      case 'deploymenttechnician':
        this.selectedDominionAuth = this.dominionAuthOptions[4];
        break;
      case 'viewer':
        this.selectedDominionAuth = this.dominionAuthOptions[5];
        break;
      default:
        this.selectedDominionAuth = undefined;
        break;
    }
  }

  setDominionAuthOptions() {
    this.dominionAuthOptions = [
      {
        label: 'Super',
        value: 'super',
        disabled: !this.canSetDominionSuper,
      },
      {
        label: 'Admin',
        value: 'admin',
        disabled: !this.canSetDominionAdmin,
      },
      {
        label: 'Project Manager',
        value: 'projectmanager',
        disabled: !this.canSetDominionProjectManager,
      },
      {
        label: 'Deployment Manager',
        value: 'deploymentmanager',
        disabled: !this.canSetDominionProjectManager,
      },
      {
        label: 'Deployment Specialist',
        value: 'deploymenttechnician',
        disabled: !this.canSetDominionProjectManager,
      },
      {
        label: 'Viewer',
        value: 'viewer',
        disabled: !this.canSetDominionProjectManager,
      },
    ];
  }

  subscribeToAuth() {
    this.authorization.authorizations.subscribe({
      next: (authorizations) => {
        if (authorizations) {
          this.initializePermissions();
        }
      },
    });
    this.authorization.user.subscribe({
      next: (actingUser) => {
        this.actingUser = actingUser;
        this.initializePermissions();
      },
    });
  }

  isActiveUser() {
    if (!this.user) return;
    return isActiveUserRead(this.user);
  }

  companyId() {
    return this.route.snapshot.paramMap.get('companyid');
  }

  deactivateUser() {
    if (!this.user) {
      return;
    }
    // do you have permission to do this?
    if (!this.canEditUserDetails || this.user._id === this.actingUser?._id) {
      throw new Error('You do not have permission to deactivate this user.');
    }
    // deactivate the user
    this.userService.deactivateUser(this.user._id).subscribe({
      next: () => {
        this.user!.isDeactivated = true;
      },
    });
  }

  reactivateUser() {
    if (!this.user) {
      return;
    }
    // do you have permission to do this?
    if (!this.canEditUserDetails) {
      throw new Error('You do not have permission to reactivate this user.');
    }
    // reactivate the user
    this.userService.reactivateUser(this.user._id).subscribe({
      next: () => {
        this.user!.isDeactivated = false;
      },
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.getUser(params['userid']);
    });
    this.route.parent?.url.subscribe((url) => {
      url[0].path === 'admin'
        ? (this.internalView = true)
        : (this.internalView = false);
    });
    this.subscribeToAuth();
  }
}
