<div class="mt-3 flex flex-1 flex-row justify-center">
  <form [formGroup]="form" class="flex w-full max-w-[500px] flex-col">
    <div dominionPopoverHost class="flex flex-row">
      <textarea
        dominionPopoverTarget
        class="ring-dms-light-green flex min-h-[250px] flex-1 rounded border border-gray-300 p-2 outline-none ring-offset-1 focus:ring"
        formControlName="text"
        name="text"
        (blur)="checkErrors()"
        (input)="checkErrors()"
      ></textarea>
      <div dominionPopover>
        <span dominionStandardFormError>Please enter a response.</span>
      </div>
    </div>
    <div class="mt-8 flex items-center">
      @if (log.length > 0) {
        <div>
          <button
            type="button"
            (click)="logModal.open()"
            class="group flex items-center gap-1 transition"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="text-gray-400"
            >
              <path d="M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M12 7v5l4 2" />
            </svg>
            <span class="text-sm text-gray-500 transition group-hover:underline"
              >History</span
            >
          </button>
        </div>
      }

      <div class="flex-1"></div>

      <button
        dominionDiscoveryNextBtn
        [disabled]="form.invalid"
        [ngClass]="{ disabled: form.invalid }"
        type="button"
        (click)="save()"
      >
        Next
      </button>
    </div>
  </form>
</div>

<dominion-modal #logModal title="Response History">
  <dominion-log body class="h-[400px]" [logs]="log"></dominion-log>
</dominion-modal>
