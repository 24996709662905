<div @firstQuestion class="mt-12 flex flex-1 flex-col">
  <!-- PROMPT -->
  <div class="mb-8 flex flex-row justify-center text-2xl">
    Which vendors do you plan to use for the following services?
  </div>
  <div class="mb-12 flex flex-row justify-center text-lg text-gray-700">
    <div class="max-w-[350px] text-center">
      Please select <strong>None</strong> if you do not plan to use a vendor for
      a particular service.
    </div>
  </div>

  <div class="flex flex-row justify-center">
    <div
      class="border-dms-light-grey flex max-w-3xl flex-1 flex-col rounded border [&>*:nth-child(even)]:bg-gray-50"
    >
      <div
        *ngFor="let question of vendorQuestions"
        class="flex flex-row items-center p-2"
      >
        <div class="flex flex-1 basis-1/2 text-lg font-semibold">
          {{ question.label }}
        </div>
        <div class="flex basis-1/2">
          <dominion-dropdown-search
            class="flex-1"
            [options]="question.vendorOptions ? question.vendorOptions : []"
            [createNewLabel]="'Add a Vendor'"
            [enableCreateNew]="true"
            [toggleTextSize]="'text-lg'"
            [viewType]="'form'"
            [ring]="true"
            [dropdownCaret]="true"
            (emitCreateNew)="openCustomVendorModal(question.questionKey)"
            (emitSelectedValue)="
              saveVendorResponse(question.questionKey, $event)
            "
            [selectedOption]="
              selectedOptions[question.questionKey]
                ? selectedOptions[question.questionKey]
                : undefined
            "
            [toggleText]="'Select an Option'"
          ></dominion-dropdown-search>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-12 flex flex-row justify-center">
    <button dominionDiscoveryNextBtn type="button" [routerLink]="['../../../']">
      Next
    </button>
  </div>

  <dominion-modal
    #customVendorModal
    title="Add a Custom Vendor"
    (emitClosed)="clearAndResetCustomModal()"
  >
    <div
      body
      class="flex min-h-[400px] min-w-[500px] flex-col overflow-hidden pt-4"
    >
      <form
        [formGroup]="customVendorForm"
        (ngSubmit)="saveCustomVendor()"
        class="flex flex-1 flex-col"
      >
        <!-- PROVIDER NAME -->
        <!-- provider name error -->
        <div
          *ngIf="
            customVendorForm.get('providerName')?.invalid &&
            customVendorForm.get('providerName')?.touched
          "
          class="mt-6 flex flex-row"
        >
          <div class="flex-1">
            <div dominionStandardFormError>Please enter a provider name.</div>
          </div>
        </div>
        <!-- provider name label -->
        <div
          *ngIf="
            customVendorForm.get('providerName')?.valid ||
            customVendorForm.get('providerName')?.untouched
          "
          class="mt-6 flex flex-row"
        >
          <div class="flex-1">
            <div dominionStandardFormLabel [required]="true">Provider Name</div>
          </div>
        </div>
        <!-- provider name text input -->
        <div class="flex flex-row">
          <div class="flex-1">
            <input
              #providerNameInput
              class="outline-dms-light-green w-full overflow-hidden rounded-sm border p-2 text-center text-sm placeholder:font-light placeholder:italic"
              type="text"
              formControlName="providerName"
              placeholder="Provider Name"
            />
          </div>
        </div>
        <!-- APP NAME -->
        <!-- app name error -->
        <div
          *ngIf="
            customVendorForm.get('appName')?.invalid &&
            customVendorForm.get('appName')?.touched
          "
          class="mt-6 flex flex-row"
        >
          <div class="flex-1">
            <div dominionStandardFormError>
              Please enter an application name.
            </div>
          </div>
        </div>
        <!-- app name label -->
        <div
          *ngIf="
            customVendorForm.get('appName')?.valid ||
            customVendorForm.get('appName')?.untouched
          "
          class="mt-6 flex flex-row"
        >
          <div class="flex-1">
            <div dominionStandardFormLabel [required]="false">
              Application Name
            </div>
          </div>
        </div>
        <!-- app name text input -->
        <div class="flex flex-row">
          <div class="flex-1">
            <input
              #appNameInput
              class="outline-dms-light-green w-full overflow-hidden rounded-sm border p-2 text-center text-sm placeholder:font-light placeholder:italic"
              type="text"
              formControlName="appName"
              placeholder="Application Name"
            />
          </div>
        </div>

        <!-- SUBMIT & CANCEL -->
        <div class="mt-8 flex flex-row">
          <div
            dominionStandardFormCancelBtn
            (click)="cancelCreateCustomVendor()"
            class="flex-1"
          >
            Cancel
          </div>
          <div class="flex-1">
            <dominion-standard-form-submit
              type="submit"
              [disabled]="!customVendorForm.valid"
              text="Create Vendor"
              [isLoading]="isSavingCustomVendor"
            ></dominion-standard-form-submit>
          </div>
        </div>
      </form>
    </div>
  </dominion-modal>
</div>
