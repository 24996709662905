<div class="mb-5 flex flex-row items-center">
  <div class="flex flex-row items-center space-x-2 pr-6">
    <span class="text-sm uppercase text-gray-500">Dominion Team</span>
    <dominion-information-button [buttonHeight]="'h-4'" [buttonWidth]="'w-4'">
      <div class="w-56 p-2">
        <p>
          This section shows the DominionDMS team members who have been assigned
          to your project.
        </p>
      </div>
    </dominion-information-button>
  </div>
</div>

<!-- PROJECT MANAGER -->
<div
  class="text-dms-green flex flex-row justify-center text-xs font-light uppercase"
>
  Project Manager
</div>
<div
  class="text-dms-ultra-grey my-[1px] flex flex-row items-center justify-center font-bold"
>
  <dominion-dropdown-search
    [options]="assignableOptions.projectmanagers"
    [selectedOption]="selectedProjectManager"
    [viewType]="'editable'"
    [toggleText]="'None Assigned'"
    [togglePaddingX]="'px-1'"
    [togglePaddingY]="'py-0'"
    [disabled]="!canAssignProjectManager"
    (emitSelectedValue)="assignProjectManager($event)"
  ></dominion-dropdown-search>
</div>
<div class="flex flex-row justify-center text-xs font-light text-gray-600">
  {{ company?.internalAssigned?.projectmanager?.email }}
</div>

@if (internalView) {
  <!-- DEPLOYMENT MANAGER -->
  <div
    class="text-dms-green mt-7 flex flex-row justify-center text-xs font-light uppercase"
  >
    Deployment Manager
  </div>
  <div
    class="text-dms-ultra-grey my-[1px] flex flex-row items-center justify-center font-bold"
  >
    <dominion-dropdown-search
      [options]="assignableOptions.deploymentmanagers"
      [selectedOption]="selectedDeploymentManager"
      [viewType]="'editable'"
      [toggleText]="'None Assigned'"
      [togglePaddingX]="'px-1'"
      [togglePaddingY]="'py-0'"
      [disabled]="!canAssignDeploymentManager"
      (emitSelectedValue)="assignDeploymentManager($event)"
    ></dominion-dropdown-search>
  </div>
  <div class="flex flex-row justify-center text-xs font-light text-gray-600">
    {{ company?.internalAssigned?.deploymentmanager?.email }}
  </div>

  <!-- DEPLOYMENT TECHNICIAN -->
  <div
    class="text-dms-green mt-7 flex flex-row justify-center text-xs font-light uppercase"
  >
    Deployment Specialist
  </div>
  <div
    class="text-dms-ultra-grey my-[1px] flex flex-row items-center justify-center font-bold"
  >
    <dominion-dropdown-search
      [options]="assignableOptions.deploymenttechnicians"
      [selectedOption]="selectedDeploymentTechnician"
      [viewType]="'editable'"
      [toggleText]="'None Assigned'"
      [togglePaddingX]="'px-1'"
      [togglePaddingY]="'py-0'"
      [disabled]="!canAssignDeploymentTechnician"
      (emitSelectedValue)="assignDeploymentTechnician($event)"
    ></dominion-dropdown-search>
  </div>
  <div class="flex flex-row justify-center text-xs font-light text-gray-600">
    {{ company?.internalAssigned?.deploymenttechnician?.email }}
  </div>
}
