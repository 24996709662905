import { Types } from 'mongoose';
import { ResponseLog, States, UserType } from '@dominion/interfaces';

export type ModuleType =
  | 'sales'
  | 'service'
  | 'parts'
  | 'accounting'
  | 'payroll'
  | 'fi'
  | 'infrastructure';

export interface IModuleDataLog<T> {
  date: Date;
  user: {
    userId: string; // OID
    firstName: string;
    lastName: string;
    userType: UserType;
  };
  value: T;
  previousValue: T;
}

export interface ICoreSubmoduleReference {
  submoduleId: string;
  isAvailable: boolean;
  completion: {
    total: number;
    complete: number;
    groups: {
      [key: string]: {
        total: number;
        complete: number;
      };
    };
  };
  dueDate: Date | null;
}

export interface ICoreModule {
  _id: string;
  name: string;
  moduleType: ModuleType;
  desc: string;
  dateCreated: Date;
  createdBy: string;
  externalAssigned: string[];
  submodules: {
    preliminary: ICoreSubmoduleReference;
    maintenance: ICoreSubmoduleReference;
    default: ICoreSubmoduleReference;
  };
}

export class CoreModule implements ICoreModule {
  _id: string;
  name: string;
  moduleType: ModuleType;
  desc: string;
  dateCreated: Date;
  createdBy: string;
  externalAssigned: string[];
  submodules: {
    preliminary: ICoreSubmoduleReference;
    maintenance: ICoreSubmoduleReference;
    default: ICoreSubmoduleReference;
  };
  constructor(initializer: ModuleCreateInitializer | ICoreModule) {
    // if we are creating an instance from an existing module
    if ('dateCreated' in initializer) {
      Object.assign(this, initializer);
      // if we are creating a brand new module instance
    } else {
      // here we want to initialize with provided properties and then default the rest
      this.initializeNewModule(initializer);
    }
  }
  initializeNewModule(initializer: ModuleCreateInitializer) {
    // defined with default
    this._id = initializer._id.toString();
    this.dateCreated = new Date();
    this.externalAssigned = [];
    // defined with initializer or default
    this.name = initializer.name || '';
    this.desc = initializer.desc || '';
    // defined with initializer
    this.moduleType = initializer.moduleType;
    this.createdBy = initializer.createdBy;
    this.submodules = initializer.submodules;
  }
}

// MODULE MODEL

export interface ICoreModuleOverrides {
  _id: Types.ObjectId;
  createdBy: Types.ObjectId;
  externalAssigned: Types.ObjectId[];
}

export interface ICoreModuleSubdocument
  extends Omit<ICoreModule, '_id' | 'createdBy' | 'externalAssigned'>,
    ICoreModuleOverrides {}

export class DataInitializerBasic<T> {
  public value: T | null;
  public log: ResponseLog[];
  constructor(initialValue?: T) {
    this.value = initialValue || null;
    this.log = [];
  }
}

export class DataInitializerOther<T> {
  public value: T | null;
  public otherValue: string | null;
  public log: ResponseLog[];
  constructor() {
    this.value = null;
    this.otherValue = null;
    this.log = [];
  }
}

export interface IAddressData {
  street: string;
  city: string;
  state: States;
  zip: string;
}

/*
  CREATE
*/

export interface ModuleCreateInitializer
  extends Pick<ICoreModule, 'moduleType' | 'createdBy' | 'submodules'> {
  _id: Types.ObjectId;
  name?: string;
  desc?: string;
}

export interface ICoreModuleCreate {
  companyId: string;
  name?: string;
  desc?: string;
  moduleType: ModuleType;
}

/*
  READ
*/

export interface ICoreModuleReadBrief
  extends Pick<ICoreModule, '_id' | 'name' | 'desc' | 'moduleType'> {}

export interface ICoreModuleReadFull extends ICoreModule {}

export interface IGetModuleDto {
  companyId: string;
  moduleId: string;
}

export interface IGetSubmoduleDataDto {
  submoduleId: string;
}

/*
  UPDATE
*/

// CORE MODULE DETAILS

export interface ICoreModuleUpdate extends Partial<Pick<ICoreModule, 'name'>> {
  companyId: string;
  moduleId: string;
}

// CORE MODULE ASSIGNED USER

export interface ICoreModuleAddUserDto {
  companyId: string;
  moduleId: string;
  userId: string;
}

// SUBMODULE DETAILS

export interface ISubmoduleUpdate
  extends Partial<Pick<ICoreSubmoduleReference, 'dueDate' | 'isAvailable'>> {
  companyId: string;
  moduleId: string;
  submoduleId: string;
}

export interface ISubmoduleResponse {
  submoduleId: string;
  response: {
    field: string;
    value: unknown;
  };
}

export interface IGetSubmoduleFieldsBase {
  submoduleId: string;
  fields: string[];
}

/*
  UI EVENTS
*/

export interface ICoreModuleUpdateEvent {
  moduleId: string | Types.ObjectId;
  field: keyof ICoreModule;
  value: ICoreModule[keyof ICoreModule];
}

export interface ISubmoduleUpdateEvent {
  moduleId: string | Types.ObjectId;
  submoduleId: string;
  field: keyof ICoreSubmoduleReference;
  value: ICoreSubmoduleReference[keyof ICoreSubmoduleReference];
}

/*
  COMPLETION AUDIT
*/

export type IModuleGroupCompletionStatus =
  | 'complete'
  | 'in-progress'
  | 'not-started';

//
//
// Module Structure Interfaces
