import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { PopoverDirective } from '../shared/directives/popover.directive';
import { PopoverHostDirective } from '../shared/directives/popover-host.directive';
import { PopoverTargetDirective } from '../shared/directives/popover-target.directive';
import { SharedModule } from '../shared/shared.module';

@Component({
  selector: 'dominion-form-input',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    PopoverDirective,
    PopoverHostDirective,
    PopoverTargetDirective,
  ],
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.css'],
})
export class FormInputComponent implements OnInit {
  @Input() id: string;
  @Input() type: 'text' | 'textarea' | 'password' = 'text';
  @Input() label: string;
  @Input() required = false;
  @Input() placeholder = '';
  @Input() errorMessage: string | null;
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;

  @ViewChild(PopoverHostDirective) host: PopoverHostDirective;

  constructor() {
    this.formGroup = new FormGroup({
      [this.formControlName]: new FormControl(
        '',
        this.required ? Validators.required : null,
      ),
    });
  }

  ngOnInit() {
    if (typeof this.id === 'undefined') {
      this.id = this.formControlName;
    }
  }

  checkError() {
    const isInvalid =
      this.formGroup.get(this.formControlName)!.invalid &&
      this.formGroup.get(this.formControlName)!.touched
        ? true
        : false;
    if (isInvalid) {
      this.host.show();
    } else {
      this.host.hide();
    }
  }

  get hasError() {
    const control = this.formGroup.get(this.formControlName);
    return control?.invalid && control?.touched;
  }
}
