import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  Pipe,
  PipeTransform,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  CompanyAuthRole,
  ICompanyReadFull,
  IRequestUserPayload,
  IUserResourceAuth,
  IInvitedUserCreate,
  IUserExternalAuthDto,
} from '@dominion/interfaces';
import { DropdownOption } from '../../shared/dropdown-search/dropdown-search.component';
import { ModalComponent } from '../../shared/modal/modal.component';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { InformationButtonComponent } from '../../shared/information-button/information-button.component';
import { IconPlusCircleComponent } from '../../icons/icon-plus-circle.component';
import { IconGoToComponent } from '../../icons/icon-go-to.component';
import { InSituSelectComponent } from '../../shared/inputs/in-situ-select/in-situ-select.component';
import { CompanyService } from '../company.service';
import { TableComponent } from '../../shared/table/table.component';

@Pipe({
  name: 'externalUserRole',
  standalone: true,
})
export class ExternalUserRolePipe implements PipeTransform {
  transform(
    user:
      | ICompanyReadFull['users'][number]
      | ICompanyReadFull['invitedUsers'][number],
    companyId: string,
  ) {
    const authKey = 'company:' + companyId;
    let auth: IUserResourceAuth;
    try {
      auth = user.authorizations[authKey];
    } catch (err) {
      return null;
    }
    return auth.roles[0];
  }
}

@Pipe({
  name: 'userRolePermissions',
  standalone: true,
})
export class UserRolePermissionsPipe implements PipeTransform {
  transform(canInviteSuper: boolean, canInviteAdmin: boolean) {
    return [
      {
        label: 'Super',
        value: 'super',
        disabled: !canInviteSuper,
      },
      {
        label: 'Admin',
        value: 'admin',
        disabled: !canInviteAdmin,
      },
      {
        label: 'Manager',
        value: 'manager',
        disabled: !canInviteAdmin,
      },
    ];
  }
}

function getUserAuthRole(
  user: ICompanyReadFull['users'][0] | ICompanyReadFull['invitedUsers'][0],
  companyId: string,
) {
  return user.authorizations['company:' + companyId].roles[0];
}

@Component({
  selector: 'dominion-company-users',
  templateUrl: './company-users.component.html',
  styleUrls: ['./company-users.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    InformationButtonComponent,
    IconPlusCircleComponent,
    UserRolePermissionsPipe,
    IconGoToComponent,
    InSituSelectComponent,
    ExternalUserRolePipe,
    TableComponent,
  ],
})
export class CompanyUsersComponent implements OnChanges {
  @HostBinding('class') class =
    'bg-white shadow-md hover:shadow-lg min-h-[350px] border border-gray-100 p-4';

  @Input() user: IRequestUserPayload | undefined;
  @Input() company: ICompanyReadFull | undefined;
  @Input() isLoading: boolean = false;
  @Input() canInviteSuper: boolean = false;
  @Input() canInviteAdmin: boolean = false;
  @Input() canInviteManager: boolean = false;
  @Input() canEditCompanyDetails: boolean = false;

  @Output() public emitAddUser: EventEmitter<IInvitedUserCreate> =
    new EventEmitter<IInvitedUserCreate>();

  @ViewChild('emailInput') emailInput: ElementRef<HTMLInputElement> | undefined;
  @ViewChild('inviteModal') inviteModal: ModalComponent;

  // Add a user
  public inviteForm: FormGroup;
  public inviteOpen = false;
  public genInviteErr: HttpErrorResponse | undefined;
  public isSendingInvite = false;
  public userOptions: DropdownOption[];
  public showInviteUser = false; // enter email manually in text input field
  public showCompanyStrategy = false; // allow user to select how to apply to companies in group
  public selectedUser: DropdownOption | undefined;
  public selectedRole: DropdownOption | undefined;
  public roleOptions: DropdownOption[] = [];
  public companyOptions: DropdownOption[] = [
    {
      label: 'This Company Only',
      value: 'one',
    },
    {
      label: 'All Companies in Group',
      value: 'all',
    },
  ];
  public selectedStrategy: DropdownOption | undefined;
  public isPartOfGroup: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private companyService: CompanyService,
  ) {
    this.inviteForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      role: ['', Validators.required],
      companyStrategy: ['', Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['company']) {
      this.userOptions = this.getUserOptions();
    }
    if (
      changes['canInviteSuper'] ||
      changes['canInviteAdmin'] ||
      changes['canInviteManager']
    ) {
      this.setRoleOptions();
    }
  }

  goToUserDetail(targetUserId: string) {
    if (this.user) {
      if (this.user.userType === 'internal') {
        this.router.navigate(['/core/admin/users', targetUserId]);
      } else {
        this.router.navigate([
          '/core',
          this.company?._id,
          'users',
          targetUserId,
        ]);
      }
    }
  }

  public setRoleOptions(): void {
    this.roleOptions = [
      {
        label: 'Super',
        value: 'super',
        disabled: !this.canInviteSuper,
      },
      {
        label: 'Admin',
        value: 'admin',
        disabled: !this.canInviteAdmin,
      },
      {
        label: 'Manager',
        value: 'manager',
        disabled: !this.canInviteManager,
      },
    ];
  }

  private getGroupStatus(): boolean {
    if (this.company) {
      if (
        this.company.childCompanies.length > 0 ||
        this.company.parentCompany
      ) {
        return true;
      }
    }
    return false;
  }

  public openInvite() {
    this.inviteForm.reset();
    this.inviteOpen = true;
    const isPartOfGroup = this.getGroupStatus();
    this.isPartOfGroup = isPartOfGroup;
    if (isPartOfGroup) {
      this.inviteForm
        .get('companyStrategy')!
        .setValidators(Validators.required);
      this.inviteForm.get('companyStrategy')!.updateValueAndValidity();
      this.inviteModal.open();
    } else {
      this.inviteForm.get('companyStrategy')!.clearValidators();
      this.inviteForm.get('companyStrategy')!.updateValueAndValidity();
      this.showInviteUser = true;
      this.inviteModal.open();
    }
  }
  // if has children or has parent, then part of a group

  public addUser() {
    if (this.inviteForm.invalid) {
      return;
    }
    this.genInviteErr = undefined;
    this.isSendingInvite = true;
    const dto = this.buildAddUserDto();
    this.emitAddUser.emit(dto);
  }

  public cancelInviteOpen() {
    this.inviteOpen = false;
    this.inviteForm.reset();
    this.genInviteErr = undefined;
  }

  public inviteSucceeded() {
    this.inviteForm.reset();
    this.selectedRole = undefined;
    this.selectedUser = undefined;
    this.selectedStrategy = undefined;
    this.isSendingInvite = false;
  }

  public setGenErr(err: HttpErrorResponse) {
    this.isSendingInvite = false;
    this.genInviteErr = err;
  }

  public markUserDropdownAsTouched() {
    this.inviteForm.get('email')?.markAsTouched();
  }

  public toggleInviteUser() {
    if (this.showInviteUser) {
      this.showInviteUser = false;
      this.inviteForm.get('email')?.reset();
      this.selectedUser = undefined;
    } else {
      this.showInviteUser = true;
      this.inviteForm.get('email')?.reset();
      this.selectedUser = undefined;
      setTimeout(() => {
        this.emailInput?.nativeElement.focus();
      });
    }
  }

  public selectUser(option: DropdownOption) {
    this.inviteForm.get('email')?.setValue(option.value);
    this.selectedUser = option;
    this.markUserDropdownAsTouched();
  }

  public markRoleDropdownAsTouched() {
    this.inviteForm.get('role')?.markAsTouched();
  }

  public selectRole(option: DropdownOption) {
    this.inviteForm.get('role')?.setValue(option.value);
    this.selectedRole = option;
    this.markRoleDropdownAsTouched();
  }

  public markCompanyStrategyDropdownAsTouched() {
    this.inviteForm.get('companyStrategy')?.markAsTouched();
  }

  public selectCompanyStrategy(option: DropdownOption) {
    this.inviteForm.get('companyStrategy')?.setValue(option.value);
    this.selectedStrategy = option;
    this.markCompanyStrategyDropdownAsTouched();
  }

  buildUserLabel(
    user: ICompanyReadFull['users'][0] | ICompanyReadFull['invitedUsers'][0],
  ) {
    if ('firstName' in user) {
      return `${user.firstName} ${user.lastName}`;
    } else {
      return `${user.email}`;
    }
  }

  getUserOptions() {
    // if no company or this company is not part of a group, then return empty array
    if (
      !this.company ||
      (!this.company.parentCompany && this.company.childCompanies.length === 0)
    ) {
      return [];
    }
    // get the invited and active users from this company or its parent company
    const invitedUsers = this.company.parentCompany
      ? this.company.parentCompany.invitedUsers
      : this.company.invitedUsers;
    const users = this.company.parentCompany
      ? this.company.parentCompany.users
      : this.company.users;
    // merge the invited and active users
    const merged = users.concat(invitedUsers as any);
    // map the merged users to a label/value pair
    const allUsers = merged.map((u) => {
      return {
        label: this.buildUserLabel(u),
        value: u.email,
      };
    });
    // sort the users by label
    const sorted = allUsers.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      } else if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    });
    return sorted;
  }

  buildAddUserDto(): IInvitedUserCreate {
    const dto: IInvitedUserCreate = {
      email: this.inviteForm.get('email')?.value,
      companyStrategy: this.inviteForm.get('companyStrategy')?.value
        ? this.inviteForm.get('companyStrategy')?.value
        : 'one',
      companyRoles: [
        {
          role: this.inviteForm.get('role')?.value,
          companyId: this.company!._id as string,
        },
      ],
    };
    return dto;
  }

  setUserAuthRole(
    user: ICompanyReadFull['users'][0] | ICompanyReadFull['invitedUsers'][0],
    role: unknown,
    component: InSituSelectComponent,
  ) {
    if (
      !this.canEditCompanyDetails &&
      (!this.canInviteAdmin || role === 'super')
    ) {
      throw new Error('You do not have permission to perform this action.');
    }
    const dto: IUserExternalAuthDto = {
      companyId: this.company!._id,
      userEmail: user.email,
      role: role as CompanyAuthRole,
    };
    this.companyService.setExternalUserAuthRole(dto).subscribe({
      next: () => {
        user.authorizations['company:' + this.company!._id].roles = [
          role as CompanyAuthRole,
        ];
        const userIndex = this.company!.users.findIndex(
          (u) => u.email === user.email,
        );
        const invitedIndex = this.company!.invitedUsers.findIndex(
          (u) => u.email === user.email,
        );
        if (userIndex >= 0) {
          this.company!.users[userIndex] = {
            ...user,
          } as ICompanyReadFull['users'][0];
        } else if (invitedIndex >= 0) {
          this.company!.invitedUsers[invitedIndex] = {
            ...user,
          } as ICompanyReadFull['invitedUsers'][0];
        }
        component.handleSuccess();
      },
      error: (err) => {
        component.handleError(err);
      },
    });
  }
}
