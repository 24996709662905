<div class="flex grow flex-col border border-gray-100 shadow-md">
  <div
    class="flex flex-row items-center justify-between p-4 pb-0 text-sm uppercase text-gray-500"
  >
    <div>{{ title }} {{ count ? '(' + count + ')' : '' }}</div>
    @if (titleRight) {
      <div>{{ titleRight }}</div>
    }
  </div>
  <ng-content></ng-content>
</div>
